@font-face {
  font-family: "brown__regular";
  src: url("../fonts/brown__regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap
}
@font-face {
  font-family: "brown__bold";
  src: url("../fonts/brown__bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap
}
@font-face {
  font-family: "gt_america__mono";
  src: url("../fonts/gt_america__mono.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: none;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block
}
body {
  line-height: 1
}
ol,
ul {
  list-style: none
}
blockquote,
q {
  quotes: none
}
blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none
}
table {
  border-collapse: collapse;
  border-spacing: 0
}
:root {
  --ratio: 1.12;
  --fz-ratio-power--nine: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--eight: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--seven: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--six: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--five: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--four: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--three: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--two: calc(1rem * var(--ratio) * var(--ratio));
  --fz-ratio-power--one: calc(1rem * var(--ratio));
  --fz-ratio-power--minus-one: calc(1rem / var(--ratio));
  --fz-ratio-power--minus-two: calc((1rem / var(--ratio)) / var(--ratio));
  --fz-ratio-power--minus-three: calc((1rem / var(--ratio)) / var(--ratio) / var(--ratio));
  --fz-ratio-power--minus-four: calc((1rem / var(--ratio)) / var(--ratio) / var(--ratio) / var(--ratio));
  --sp-ratio-power--four: calc(var(--fz-ratio-power--four) * 1.45);
  --sp-ratio-power--three: calc(var(--fz-ratio-power--three) * 1.45);
  --sp-ratio-power--two: calc(var(--fz-ratio-power--two) * 1.45);
  --sp-ratio-power--one: calc(var(--fz-ratio-power--one) * 1.45)
}
@media only screen and (min-width:581px) {
  :root {
    --ratio: 1.15
  }
}
@media only screen and (min-width:881px) {
  :root {
    --ratio: 1.18
  }
}
@-moz-keyframes rotation {
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(359deg)
  }
}
@-webkit-keyframes rotation {
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(359deg)
  }
}
@-o-keyframes rotation {
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(359deg)
  }
}
@keyframes rotation {
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(359deg)
  }
}
@-moz-keyframes careers-background {
  0% {
    transform: translateX(0);
    opacity: 0
  }
  25% {
    transform: translateX(100px);
    opacity: 100
  }
  75% {
    transform: translateX(300px);
    opacity: 100
  }
  100% {
    transform: translateX(400px);
    opacity: 0
  }
}
@-webkit-keyframes careers-background {
  0% {
    transform: translateX(0);
    opacity: 0
  }
  25% {
    transform: translateX(100px);
    opacity: 100
  }
  75% {
    transform: translateX(300px);
    opacity: 100
  }
  100% {
    transform: translateX(400px);
    opacity: 0
  }
}
@-o-keyframes careers-background {
  0% {
    transform: translateX(0);
    opacity: 0
  }
  25% {
    transform: translateX(100px);
    opacity: 100
  }
  75% {
    transform: translateX(300px);
    opacity: 100
  }
  100% {
    transform: translateX(400px);
    opacity: 0
  }
}
@keyframes careers-background {
  0% {
    transform: translateX(0);
    opacity: 0
  }
  25% {
    transform: translateX(100px);
    opacity: 100
  }
  75% {
    transform: translateX(300px);
    opacity: 100
  }
  100% {
    transform: translateX(400px);
    opacity: 0
  }
}
@-moz-keyframes home-background {
  0% {
    transform: rotate(0);
    transform: scale(1)
  }
  50% {
    transform: rotate(10deg);
    transform: scale(1.1)
  }
  100% {
    transform: rotate(0);
    transform: scale(1)
  }
}
@-webkit-keyframes home-background {
  0% {
    transform: rotate(0);
    transform: scale(1)
  }
  50% {
    transform: rotate(10deg);
    transform: scale(1.1)
  }
  100% {
    transform: rotate(0);
    transform: scale(1)
  }
}
@-o-keyframes home-background {
  0% {
    transform: rotate(0);
    transform: scale(1)
  }
  50% {
    transform: rotate(10deg);
    transform: scale(1.1)
  }
  100% {
    transform: rotate(0);
    transform: scale(1)
  }
}
@keyframes home-background {
  0% {
    transform: rotate(0);
    transform: scale(1)
  }
  50% {
    transform: rotate(10deg);
    transform: scale(1.1)
  }
  100% {
    transform: rotate(0);
    transform: scale(1)
  }
}
@-moz-keyframes global-navigation-display {
  100% {
    transform: translateX(0%)
  }
}
@-webkit-keyframes global-navigation-display {
  100% {
    transform: translateX(0%)
  }
}
@-o-keyframes global-navigation-display {
  100% {
    transform: translateX(0%)
  }
}
@keyframes global-navigation-display {
  100% {
    transform: translateX(0%)
  }
}
@-moz-keyframes opacity_to_full {
  to {
    opacity: 1
  }
}
@-webkit-keyframes opacity_to_full {
  to {
    opacity: 1
  }
}
@-o-keyframes opacity_to_full {
  to {
    opacity: 1
  }
}
@keyframes opacity_to_full {
  to {
    opacity: 1
  }
}
html * {
  will-change: outline-offset;
  transition-property: outline-offset;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  outline-offset: 0
}
html[data-focus-source="key"] *:focus {
  z-index: 100;
  outline-offset: 4px;
  outline: 2px dashed #ffad41;
  overflow: visible
}
html[data-focus-source=""] *:focus,
html[data-focus-source="pointer"] *:focus,
html[data-focus-source="script"] *:focus {
  outline: none
}
*,
*::before,
*::after {
  box-sizing: border-box
}
html {
  scroll-behavior: smooth
}
@media (prefers-reduced-motion:reduce) {
  html {
    scroll-behavior: auto
  }
}
.application_container {
  will-change: unset;
  transform: none;
  filter: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
  background-color: #040f25
}
.application_container--modal_is_visible {
  overflow: hidden;
  max-height: 100vh
}
.global_and_local_headers {
  position: relative;
  z-index: auto;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 100%
}
.global_and_local_headers__backgrounds__repeated_logo {
  position: absolute;
  z-index: auto;
  top: -3.3rem;
  left: -27.8rem;
  width: 100%;
  max-width: 100%
}
.global_and_local_headers__backgrounds__repeated_logo svg {
  width: 175rem;
  max-width: none;
  opacity: .8
}
.global_and_local_headers__backgrounds__gradient {
  position: absolute;
  z-index: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to top right,hsla(220,82%,8%,0.9),hsla(220,82%,8%,0))
}
.main_content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
  background-color: #f6f7f9
}
.page_content {
  flex: 1 0 auto;
  width: 100%
}
.page_content__inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1480px
}
.page_content__inner > *:first-child {
  margin-top: 3.236rem
}
.page_content__inner > *:last-child {
  margin-bottom: 3.236rem
}
img {
  display: block;
  height: auto;
  width: auto;
  max-width: 100%;
  vertical-align: bottom
}
img[height][width] {
  height: auto
}
img[width] {
  width: auto
}
img[src$=".svg"] {
  height: auto;
  width: 100%;
  max-width: none
}
svg {
  overflow: visible;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%
}
button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  font: inherit;
  color: inherit
}
.anchor_further {
  will-change: border-bottom-color,color;
  transition-property: border-bottom-color,color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: .5393333333333333rem;
  border-bottom: 1px solid #b9d0ff;
  padding-bottom: .26966666666666667rem;
  line-height: 1.1;
  color: #0f45b2
}
* + .anchor_further {
  margin-top: 2.156794rem
}
.anchor_further.anchor_further--dark_background {
  border-bottom-color: rgba(255,255,255,0.8)
}
.anchor_further.anchor_further--dark_background:active,
.anchor_further.anchor_further--dark_background:focus,
.anchor_further.anchor_further--dark_background:hover {
  border-bottom-color: #fff
}
.anchor_further.anchor_further--dark_background:active .anchor_further__icon,
.anchor_further.anchor_further--dark_background:focus .anchor_further__icon,
.anchor_further.anchor_further--dark_background:hover .anchor_further__icon,
.anchor_further.anchor_further--dark_background:active .anchor_further__text,
.anchor_further.anchor_further--dark_background:focus .anchor_further__text,
.anchor_further.anchor_further--dark_background:hover .anchor_further__text {
  color: #fff
}
.anchor_further.anchor_further--dark_background .anchor_further__icon {
  color: rgba(255,255,255,0.8)
}
.anchor_further.anchor_further--dark_background .anchor_further__text {
  color: #fff
}
.anchor_further--secondary .anchor_further__icon svg {
  height: .7rem;
  width: .7rem
}
.anchor_further--secondary .anchor_further__text {
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one)
}
@media only screen and (min-width:581px) {
  .anchor_further--secondary .anchor_further__text {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  .anchor_further--secondary .anchor_further__text {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
.anchor_further:active,
.anchor_further:focus,
.anchor_further:hover {
  color: #0036a1;
  border-bottom-color: #689aff
}
.anchor_further:active .anchor_further__icon,
.anchor_further:focus .anchor_further__icon,
.anchor_further:hover .anchor_further__icon {
  color: #689aff
}
.anchor_further__icon {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  flex: 0 0 auto;
  display: flex;
  color: #b9d0ff
}
.anchor_further__icon svg {
  height: .809rem;
  width: .809rem
}
.anchor_further__text {
  flex: 0 1 auto;
  line-height: 1.1;
  transform: translateY(.05em)
}
* + .required_fields_key {
  margin-top: 1.618rem
}
.required_fields_key__text {
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one)
}
@media only screen and (min-width:581px) {
  .required_fields_key__text {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  .required_fields_key__text {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
.required_fields_key__text > span {
  color: #ffb1b5
}
.infographic {
  display: flex;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1480px;
  margin-bottom: 6.472rem;
  border: 1px solid #0f45b2;
  border-radius: 16px
}
:root {
  font-size: 12px
}
@media screen and (min-width:480px) {
  :root {
    font-size: calc(0.49504950495049505vw + 9.623762376237623px)
  }
}
@media screen and (min-width:2500px) {
  :root {
    font-size: 22px
  }
}
html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-word
}
body,
select,
input,
textarea {
  font-family: "brown__regular",sans-serif;
  font-weight: normal;
  line-height: 1.618;
  font-size: inherit;
  letter-spacing: -.04em;
  color: #232e43
}
h1,
h2,
h3,
h4 {
  display: block;
  color: #ffecd1
}
h1 {
  display: block;
  line-height: 1.45;
  font-family: "brown__bold",sans-serif;
  font-weight: normal;
  max-width: 37.5ch;
  letter-spacing: -.03em;
  font-size: 1.573519360000001rem;
  font-size: var(--fz-ratio-power--four)
}
@media only screen and (min-width:581px) {
  h1 {
    font-size: 1.749006249999999rem;
    font-size: var(--fz-ratio-power--four)
  }
}
@media only screen and (min-width:881px) {
  h1 {
    font-size: 1.93877776rem;
    font-size: var(--fz-ratio-power--four)
  }
}
.reading_content * + h1 {
  margin-top: 2.281603072000001rem;
  margin-top: var(--sp-ratio-power--four)
}
@media only screen and (min-width:581px) {
  .reading_content * + h1 {
    margin-top: 2.536059062499999rem;
    margin-top: var(--sp-ratio-power--four)
  }
}
@media only screen and (min-width:881px) {
  .reading_content * + h1 {
    margin-top: 2.811227751999999rem;
    margin-top: var(--sp-ratio-power--four)
  }
}
h2 {
  display: block;
  line-height: 1.45;
  font-family: "brown__bold",sans-serif;
  font-weight: normal;
  max-width: 45ch;
  letter-spacing: -.03em;
  font-size: 1.404928rem;
  font-size: var(--fz-ratio-power--three)
}
@media only screen and (min-width:581px) {
  h2 {
    font-size: 1.520875rem;
    font-size: var(--fz-ratio-power--three)
  }
}
@media only screen and (min-width:881px) {
  h2 {
    font-size: 1.643032rem;
    font-size: var(--fz-ratio-power--three)
  }
}
.reading_content * + h2 {
  margin-top: 2.037145600000001rem;
  margin-top: var(--sp-ratio-power--three)
}
@media only screen and (min-width:581px) {
  .reading_content * + h2 {
    margin-top: 2.20526875rem;
    margin-top: var(--sp-ratio-power--three)
  }
}
@media only screen and (min-width:881px) {
  .reading_content * + h2 {
    margin-top: 2.3823964rem;
    margin-top: var(--sp-ratio-power--three)
  }
}
h3 {
  display: block;
  line-height: 1.45;
  font-family: "brown__bold",sans-serif;
  font-weight: normal;
  max-width: 52.5ch;
  letter-spacing: -.026em;
  font-size: 1.2544rem;
  font-size: var(--fz-ratio-power--two)
}
@media only screen and (min-width:581px) {
  h3 {
    font-size: 1.3225rem;
    font-size: var(--fz-ratio-power--two)
  }
}
@media only screen and (min-width:881px) {
  h3 {
    font-size: 1.3924rem;
    font-size: var(--fz-ratio-power--two)
  }
}
.reading_content * + h3 {
  margin-top: 1.81888rem;
  margin-top: var(--sp-ratio-power--two)
}
@media only screen and (min-width:581px) {
  .reading_content * + h3 {
    margin-top: 1.917625rem;
    margin-top: var(--sp-ratio-power--two)
  }
}
@media only screen and (min-width:881px) {
  .reading_content * + h3 {
    margin-top: 2.01898rem;
    margin-top: var(--sp-ratio-power--two)
  }
}
h4 {
  display: block;
  line-height: 1.45;
  font-family: "brown__bold",sans-serif;
  font-weight: normal;
  max-width: 60ch;
  letter-spacing: -.02em;
  font-size: 1.12rem;
  font-size: var(--fz-ratio-power--one)
}
@media only screen and (min-width:581px) {
  h4 {
    font-size: 1.15rem;
    font-size: var(--fz-ratio-power--one)
  }
}
@media only screen and (min-width:881px) {
  h4 {
    font-size: 1.18rem;
    font-size: var(--fz-ratio-power--one)
  }
}
.reading_content * + h4 {
  margin-top: 1.624rem;
  margin-top: var(--sp-ratio-power--one)
}
@media only screen and (min-width:581px) {
  .reading_content * + h4 {
    margin-top: 1.6675rem;
    margin-top: var(--sp-ratio-power--one)
  }
}
@media only screen and (min-width:881px) {
  .reading_content * + h4 {
    margin-top: 1.711rem;
    margin-top: var(--sp-ratio-power--one)
  }
}
p {
  width: auto;
  max-width: 100%;
  font-family: "brown__regular",sans-serif;
  font-size: 1rem;
  line-height: 1.618;
  letter-spacing: -.04em;
  color: #232e43
}
.reading_content p,
p.reading_content {
  width: 100%;
  max-width: 75ch
}
.reading_content p a,
p.reading_content a,
.reading_content p a:visited,
p.reading_content a:visited,
.reading_content p a:visited:visited,
p.reading_content a:visited:visited {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  color: #0f45b2
}
.reading_content p a:active,
p.reading_content a:active,
.reading_content p a:visited:active,
p.reading_content a:visited:active,
.reading_content p a:visited:visited:active,
p.reading_content a:visited:visited:active,
.reading_content p a:focus,
p.reading_content a:focus,
.reading_content p a:visited:focus,
p.reading_content a:visited:focus,
.reading_content p a:visited:visited:focus,
p.reading_content a:visited:visited:focus,
.reading_content p a:hover,
p.reading_content a:hover,
.reading_content p a:visited:hover,
p.reading_content a:visited:hover,
.reading_content p a:visited:visited:hover,
p.reading_content a:visited:visited:hover {
  color: #0036a1
}
.reading_content * + p {
  margin-top: 1.618rem
}
.reading_content h1 + p,
.reading_content h2 + p {
  margin-top: 1.2135rem
}
.reading_content h3 + p,
.reading_content h4 + p {
  margin-top: 1.077588rem
}
a,
a:visited {
  will-change: background-color,box-shadow;
  transition-property: background-color,box-shadow;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  cursor: pointer;
  text-decoration: none
}
b,
strong {
  font-weight: normal;
  font-family: "brown__bold",sans-serif;
  letter-spacing: -.02em
}
i,
em {
  font-style: italic;
  font-family: "brown__regular",sans-serif
}
sup,
sub {
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one)
}
@media only screen and (min-width:581px) {
  sup,
  sub {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  sup,
  sub {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
sup {
  vertical-align: super
}
sub {
  vertical-align: sub
}
::selection {
  background-color: #0f45b2;
  background-image: unset;
  background-clip: unset;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: #fff;
  color: #fff
}
.reading_content {
  width: 100%
}
.reading_content ul,
.reading_content ol,
.reading_content dl {
  display: block;
  width: 100%;
  max-width: 77ch;
  padding-left: 2ch
}
.reading_content * + ul,
.reading_content * + ol,
.reading_content * + dl {
  margin-top: 1.077588rem
}
.reading_content ul {
  list-style-type: disc
}
.reading_content ul ul {
  list-style-type: circle
}
.reading_content ul ul ul {
  list-style-type: square
}
.reading_content ol {
  list-style-type: decimal
}
.reading_content ol ol {
  list-style-type: lower-alpha
}
.reading_content ol ol ol {
  list-style-type: lower-roman
}
.reading_content li {
  width: 100%;
  max-width: 100%
}
.reading_content li::marker {
  color: #8f9ebc
}
.reading_content li a,
.reading_content li a:visited,
.reading_content li a:visited:visited {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  color: #0f45b2
}
.reading_content li a:active,
.reading_content li a:visited:active,
.reading_content li a:visited:visited:active,
.reading_content li a:focus,
.reading_content li a:visited:focus,
.reading_content li a:visited:visited:focus,
.reading_content li a:hover,
.reading_content li a:visited:hover,
.reading_content li a:visited:visited:hover {
  color: #0036a1
}
.reading_content li ul:first-child,
.reading_content li ol:first-child {
  margin-top: .809rem
}
.reading_content * + li {
  margin-top: .809rem
}
pre {
  width: 100%;
  max-width: 75ch;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: "gt_america__mono",sans-serif;
  letter-spacing: -.04em
}
* + pre {
  margin-top: 1.618rem
}
pre a,
pre a:visited {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  color: #0f45b2
}
pre a:active,
pre a:visited:active,
pre a:focus,
pre a:visited:focus,
pre a:hover,
pre a:visited:hover {
  color: #0036a1
}
.pre {
  font-family: "gt_america__mono",sans-serif;
  letter-spacing: -.04em
}
.section_heading {
  position: relative;
  z-index: auto;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: .02em;
  line-height: 1.3;
  font-size: .7971938775510203rem;
  font-size: var(--fz-ratio-power--minus-two);
  color: #7b879d
}
@media only screen and (min-width:581px) {
  .section_heading {
    font-size: .7561436672967865rem;
    font-size: var(--fz-ratio-power--minus-two)
  }
}
@media only screen and (min-width:881px) {
  .section_heading {
    font-size: .7181844297615629rem;
    font-size: var(--fz-ratio-power--minus-two)
  }
}
.section_heading::after {
  content: "";
  position: absolute;
  z-index: auto;
  right: 0;
  bottom: -4px;
  height: 4px;
  width: 1480px;
  background-color: #e8ebf0
}
.section_summary {
  display: block;
  display: block;
  line-height: 1.45;
  font-family: "brown__bold",sans-serif;
  font-weight: normal;
  max-width: 45ch;
  letter-spacing: -.03em;
  font-size: 1.404928rem;
  font-size: var(--fz-ratio-power--three)
}
@media only screen and (min-width:581px) {
  .section_summary {
    font-size: 1.520875rem;
    font-size: var(--fz-ratio-power--three)
  }
}
@media only screen and (min-width:881px) {
  .section_summary {
    font-size: 1.643032rem;
    font-size: var(--fz-ratio-power--three)
  }
}
.reading_content * + .section_summary {
  margin-top: 2.037145600000001rem;
  margin-top: var(--sp-ratio-power--three)
}
@media only screen and (min-width:581px) {
  .reading_content * + .section_summary {
    margin-top: 2.20526875rem;
    margin-top: var(--sp-ratio-power--three)
  }
}
@media only screen and (min-width:881px) {
  .reading_content * + .section_summary {
    margin-top: 2.3823964rem;
    margin-top: var(--sp-ratio-power--three)
  }
}
.section_summary--long_form {
  width: 100%;
  max-width: 54ch;
  letter-spacing: -.026em;
  font-size: 1.2544rem;
  font-size: var(--fz-ratio-power--two)
}
@media only screen and (min-width:581px) {
  .section_summary--long_form {
    font-size: 1.3225rem;
    font-size: var(--fz-ratio-power--two)
  }
}
@media only screen and (min-width:881px) {
  .section_summary--long_form {
    font-size: 1.3924rem;
    font-size: var(--fz-ratio-power--two)
  }
}
* + .section_summary--long_form {
  margin-top: .5393333333333333rem
}
.cookie_notice {
  position: absolute;
  z-index: 90;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  overflow: auto;
  width: 100%;
  max-width: 100%;
  background-color: hsla(220,73%,14%,0.8);
  padding: 1.618rem 0
}
.cookie_notice.cookie_notice--is_visible {
  display: flex
}
.cookie_notice__inner {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 92%;
  max-width: 740px;
  box-shadow: 0 .4045rem 1.618rem #0a1b3e;
  border: 1px solid #8f9ebc;
  border-top: 2px solid #ffad41;
  background-color: #040f25
}
.cookie_notice__heading,
.cookie_notice__general_message,
.cookie_notice__specific_action,
.cookie_notice__general_actions {
  width: 100%;
  max-width: 100%
}
.cookie_notice__heading {
  padding: 1.2135rem 1.618rem
}
.cookie_notice__heading__text {
  flex: 1 1 auto;
  font-size: 2.210681407406081rem;
  font-size: var(--fz-ratio-power--seven);
  line-height: 1.1;
  font-weight: normal;
  font-family: $ff--light
}
* + .cookie_notice__heading__text {
  margin-top: 0
}
@media only screen and (min-width:581px) {
  .cookie_notice__heading__text {
    font-size: 2.660019880468749rem;
    font-size: var(--fz-ratio-power--seven)
  }
}
@media only screen and (min-width:881px) {
  .cookie_notice__heading__text {
    font-size: 3.185473900568319rem;
    font-size: var(--fz-ratio-power--seven)
  }
}
.cookie_notice__general_message,
.cookie_notice__specific_action,
.cookie_notice__general_actions {
  border-top: 1px solid #0a1b3e;
  padding: 1.618rem
}
.cookie_notice__general_message__text {
  display: block;
  font-size: .7971938775510203rem;
  font-size: var(--fz-ratio-power--minus-two);
  color: #fff
}
* + .cookie_notice__general_message__text {
  margin-top: 1.618rem
}
@media only screen and (min-width:581px) {
  .cookie_notice__general_message__text {
    font-size: .7561436672967865rem;
    font-size: var(--fz-ratio-power--minus-two)
  }
}
@media only screen and (min-width:881px) {
  .cookie_notice__general_message__text {
    font-size: .7181844297615629rem;
    font-size: var(--fz-ratio-power--minus-two)
  }
}
.cookie_notice__general_message__text a,
.cookie_notice__general_message__text a:visited,
.cookie_notice__general_message__text a:visited:visited {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  color: #0f45b2
}
.cookie_notice__general_message__text a:active,
.cookie_notice__general_message__text a:visited:active,
.cookie_notice__general_message__text a:visited:visited:active,
.cookie_notice__general_message__text a:focus,
.cookie_notice__general_message__text a:visited:focus,
.cookie_notice__general_message__text a:visited:visited:focus,
.cookie_notice__general_message__text a:hover,
.cookie_notice__general_message__text a:visited:hover,
.cookie_notice__general_message__text a:visited:visited:hover {
  color: #0036a1
}
.cookie_notice__specific_action {
  display: flex;
  flex-direction: row;
  align-items: center
}
.cookie_notice__specific_action__text {
  flex: 1 1 auto;
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one);
  letter-spacing: -.02em;
  color: #fff
}
@media only screen and (min-width:581px) {
  .cookie_notice__specific_action__text {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  .cookie_notice__specific_action__text {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
.cookie_notice__specific_action__text a,
.cookie_notice__specific_action__text a:visited,
.cookie_notice__specific_action__text a:visited:visited {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  color: #0f45b2
}
.cookie_notice__specific_action__text a:active,
.cookie_notice__specific_action__text a:visited:active,
.cookie_notice__specific_action__text a:visited:visited:active,
.cookie_notice__specific_action__text a:focus,
.cookie_notice__specific_action__text a:visited:focus,
.cookie_notice__specific_action__text a:visited:visited:focus,
.cookie_notice__specific_action__text a:hover,
.cookie_notice__specific_action__text a:visited:hover,
.cookie_notice__specific_action__text a:visited:visited:hover {
  color: #0036a1
}
.cookie_notice__specific_action__toggle {
  flex: 0 0 auto;
  display: flex
}
* + .cookie_notice__specific_action__toggle {
  margin-left: .5393333333333333rem
}
.cookie_notice__specific_action__toggle__checkbox {
  visibility: hidden;
  height: 0;
  width: 0
}
.cookie_notice__specific_action__toggle__checkbox:checked + label {
  background-color: #09a981
}
.cookie_notice__specific_action__toggle__checkbox:checked + label::after {
  left: calc(100% - 2px);
  transform: translateX(-100%)
}
.cookie_notice__specific_action__toggle__label {
  flex: 0 0 auto;
  cursor: pointer;
  text-indent: -9999px;
  width: 40px;
  height: 22px;
  background-color: #dc4048;
  display: block;
  border-radius: 22px;
  position: relative
}
.cookie_notice__specific_action__toggle__label:active::after {
  width: 24px
}
.cookie_notice__specific_action__toggle__label::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 18px;
  will-change: background-color,box-shadow;
  transition-property: background-color,box-shadow;
  transition-duration: .14s;
  transition-timing-function: .28s
}
.cookie_notice__general_actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: .809rem
}
@media only screen and (min-width:471px) {
  .cookie_notice__general_actions {
    flex-direction: row
  }
}
@media only screen and (min-width:471px) {
  .cookie_notice__general_actions {
    align-items: center
  }
}
.cookie_notice__button {
  will-change: box-shadow,transform;
  transition-property: box-shadow,transform;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  cursor: pointer;
  flex: 0 0 auto;
  box-shadow: 0 .1618rem .4045rem #0a1b3e;
  border-radius: 13.6px;
  border: 1px solid;
  word-break: keep-all;
  padding: .4045rem 1.2135rem .5393333333333333rem
}
@media only screen and (max-width:470px) {
  .cookie_notice__button {
    width: 100%
  }
}
.cookie_notice__button--dismiss {
  border-color: #f1f2f4;
  background-color: #040f25;
  color: #f1f2f4
}
.cookie_notice__button--accept {
  border-color: #f1f2f4;
  background-image: linear-gradient(to top right,#ffad41,#ffecd1);
  color: $cl--text--copy
}
.cookie_notice__button:active,
.cookie_notice__button:focus,
.cookie_notice__button:hover {
  transform: translateY(-2px);
  box-shadow: 0 .4045rem 1.618rem #0a1b3e
}
.global_footer {
  position: relative;
  z-index: auto;
  display: block;
  width: 100%;
  max-width: 100%;
  background-color: #040f25;
  padding-bottom: 1.618rem
}
.global_footer__inner {
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1480px
}
.global_footer__tagline_and_backing {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.618rem;
  padding: 1.618rem 0
}
@media only screen and (min-width:1081px) {
  .global_footer__tagline_and_backing {
    flex-direction: row
  }
}
@media only screen and (min-width:1081px) {
  .global_footer__tagline_and_backing {
    align-items: flex-end
  }
}
@media only screen and (max-width:1080px) {
  .global_footer__tagline_and_backing {
    text-wrap: balance
  }
}
.global_footer__tagline {
  flex: 1 1 auto
}
.global_footer__tagline__text {
  display: block;
  display: block;
  line-height: 1.45;
  font-family: "brown__bold",sans-serif;
  font-weight: normal;
  max-width: 60ch;
  letter-spacing: -.02em;
  font-size: 1.12rem;
  font-size: var(--fz-ratio-power--one);
  color: #ffecd1
}
@media only screen and (min-width:581px) {
  .global_footer__tagline__text {
    font-size: 1.15rem;
    font-size: var(--fz-ratio-power--one)
  }
}
@media only screen and (min-width:881px) {
  .global_footer__tagline__text {
    font-size: 1.18rem;
    font-size: var(--fz-ratio-power--one)
  }
}
.reading_content * + .global_footer__tagline__text {
  margin-top: 1.624rem;
  margin-top: var(--sp-ratio-power--one)
}
@media only screen and (min-width:581px) {
  .reading_content * + .global_footer__tagline__text {
    margin-top: 1.6675rem;
    margin-top: var(--sp-ratio-power--one)
  }
}
@media only screen and (min-width:881px) {
  .reading_content * + .global_footer__tagline__text {
    margin-top: 1.711rem;
    margin-top: var(--sp-ratio-power--one)
  }
}
@media only screen and (min-width:1081px) {
  .global_footer__tagline__text {
    width: 100%;
    max-width: 100%
  }
}
* + .global_footer__backing {
  margin-top: 2.427rem
}
@media only screen and (min-width:481px) {
  * + .global_footer__backing {
    margin-top: 1.077588rem
  }
}
@media only screen and (min-width:1081px) {
  * + .global_footer__backing {
    margin-top: 0
  }
}
@media only screen and (max-width:1080px) {
  .global_footer__backing {
    width: 100%;
    max-width: 100%
  }
}
.global_footer__backing__heading {
  display: block;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .02em;
  font-size: .7971938775510203rem;
  font-size: var(--fz-ratio-power--minus-two);
  color: rgba(255,255,255,0.8)
}
@media only screen and (min-width:581px) {
  .global_footer__backing__heading {
    font-size: .7561436672967865rem;
    font-size: var(--fz-ratio-power--minus-two)
  }
}
@media only screen and (min-width:881px) {
  .global_footer__backing__heading {
    font-size: .7181844297615629rem;
    font-size: var(--fz-ratio-power--minus-two)
  }
}
.global_footer__backing__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.427rem
}
@media only screen and (min-width:481px) {
  .global_footer__backing__list {
    flex-direction: row
  }
}
@media only screen and (min-width:481px) {
  .global_footer__backing__list {
    align-items: flex-end;
    justify-content: space-between
  }
}
@media only screen and (min-width:481px) {
  .global_footer__backing__list {
    gap: 1.077588rem
  }
}
* + .global_footer__backing__list {
  margin-top: 1.077588rem
}
.global_footer__backing__item {
  display: flex;
  flex: 0 0 auto;
  color: rgba(255,255,255,0.8)
}
@media only screen and (min-width:481px) {
  .global_footer__backing__item {
    flex: 0 1 33.333%
  }
}
@media only screen and (min-width:1081px) {
  .global_footer__backing__item {
    flex: 0 0 6rem
  }
}
@media only screen and (max-width:480px) {
  .global_footer__backing__item:first-child svg {
    max-width: 10rem
  }
}
@media only screen and (min-width:481px) and (max-width:1080px) {
  .global_footer__backing__item:first-child svg {
    max-width: 9rem
  }
}
@media only screen and (min-width:481px) {
  .global_footer__backing__item:nth-child(2) {
    display: flex;
    justify-content: center
  }
}
@media only screen and (max-width:480px) {
  .global_footer__backing__item:nth-child(2) svg {
    max-width: 9.5rem
  }
}
@media only screen and (min-width:481px) and (max-width:1080px) {
  .global_footer__backing__item:nth-child(2) svg {
    max-width: 8.5rem
  }
}
@media only screen and (min-width:1081px) {
  .global_footer__backing__item:last-child {
    flex: 0 0 9rem
  }
}
@media only screen and (min-width:481px) {
  .global_footer__backing__item:last-child {
    flex-direction: row;
    justify-content: flex-end
  }
}
.global_footer__backing__item:last-child img {
  opacity: .8
}
@media only screen and (max-width:480px) {
  .global_footer__backing__item:last-child img {
    max-width: 13rem
  }
}
@media only screen and (min-width:481px) and (max-width:1080px) {
  .global_footer__backing__item:last-child img {
    max-width: 12rem
  }
}
.global_footer__navigation_and_social {
  position: relative;
  z-index: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1.618rem;
  width: 100%;
  max-width: 100%;
  padding: 2.427rem 0 1.618rem
}
.global_footer__navigation_and_social::before {
  content: "";
  position: absolute;
  z-index: auto;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background-image: linear-gradient(to right,hsla(34.1,100%,66%,0) 0,#ffdaa9 50%,hsla(34.1,100%,66%,0) 100%)
}
@media only screen and (min-width:781px) {
  .global_footer__navigation_and_social {
    flex-direction: row
  }
}
@media only screen and (min-width:781px) {
  .global_footer__navigation_and_social {
    align-items: center
  }
}
@media only screen and (min-width:781px) {
  .global_footer__navigation {
    flex: 1 1 auto
  }
}
.global_footer__navigation__list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1.618rem
}
@media only screen and (min-width:781px) {
  .global_footer__navigation__list {
    justify-content: space-between
  }
}
@media only screen and (min-width:781px) {
  .global_footer__navigation__list {
    gap: .809rem
  }
}
.global_footer__navigation__item {
  flex: 0 0 auto;
  line-height: 1.1
}
.global_footer__navigation__anchor {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  display: block;
  line-height: 1.1;
  color: #fff
}
.global_footer__navigation__anchor:active,
.global_footer__navigation__anchor:focus,
.global_footer__navigation__anchor:hover {
  color: #fff
}
.global_footer__navigation__anchor.global_footer__navigation__anchor--is_active {
  border-bottom: 2px solid rgba(255,255,255,0.8)
}
@media only screen and (min-width:981px) {
  .global_footer__social_and_contact {
    flex: 0 0 7rem
  }
}
.global_footer__social_and_contact__list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 100%
}
@media only screen and (max-width:980px) {
  .global_footer__social_and_contact__list {
    gap: 1.077588rem
  }
}
.global_footer__social_and_contact__item {
  flex: 0 0 auto;
  display: inline-block;
  line-height: 1.1
}
@media only screen and (min-width:981px) {
  .global_footer__social_and_contact__item + .global_footer__social_and_contact__item {
    margin-left: .5rem
  }
}
.global_footer__social_and_contact__anchor {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  display: block;
  color: #fff
}
.global_footer__social_and_contact__anchor:active,
.global_footer__social_and_contact__anchor:focus,
.global_footer__social_and_contact__anchor:hover {
  color: #fff
}
.global_footer__social_and_contact__anchor__icon {
  display: flex
}
.global_footer__social_and_contact__anchor__icon svg {
  height: 1.618rem;
  width: 1.618rem
}
.global_footer__legal_information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1.618rem;
  border-top: 1px solid #040f25;
  padding-top: 1.618rem;
  font-size: .7971938775510203rem;
  font-size: var(--fz-ratio-power--minus-two);
  color: rgba(255,255,255,0.8)
}
@media only screen and (min-width:981px) {
  .global_footer__legal_information {
    flex-direction: row
  }
}
@media only screen and (min-width:981px) {
  .global_footer__legal_information {
    align-items: center
  }
}
@media only screen and (min-width:581px) {
  .global_footer__legal_information {
    font-size: .7561436672967865rem;
    font-size: var(--fz-ratio-power--minus-two)
  }
}
@media only screen and (min-width:881px) {
  .global_footer__legal_information {
    font-size: .7181844297615629rem;
    font-size: var(--fz-ratio-power--minus-two)
  }
}
.global_footer__legal_information__disclaimer {
  flex: 1 1 auto;
  display: block;
  max-width: 100%;
  text-align: justify
}
.global_footer__legal_information__list {
  flex: 0 0 7rem
}
@media only screen and (min-width:981px) {
  .global_footer__legal_information__item {
    text-align: right
  }
}
.global_header {
  position: relative;
  z-index: 20;
  display: block;
  width: 100%;
  max-width: 100%
}
.global_header__announcement {
  display: block;
  width: 100%;
  max-width: 100%;
  border-bottom: 1px solid #000510;
  background-color: #0a1b3e;
  padding: .809rem 1.077588rem;
  color: rgba(255,255,255,0.8)
}
@media only screen and (min-width:581px) {
  .global_header__announcement {
    padding: .5393333333333333rem 1.077588rem
  }
}
.global_header__announcement__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: .809rem;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1480px
}
@media only screen and (min-width:581px) {
  .global_header__announcement__text {
    flex-direction: row
  }
}
@media only screen and (min-width:581px) {
  .global_header__announcement__text {
    align-items: center
  }
}
@media only screen and (min-width:581px) {
  .global_header__announcement__text {
    gap: 1.077588rem
  }
}
.global_header__announcement__message {
  flex: 0 0 auto;
  line-height: 1.1
}
.global_header__announcement__anchor_signifier {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .4045rem
}
.global_header__announcement__anchor_signifier__icon {
  display: flex;
  transform: translateY(-.05rem);
  color: rgba(255,255,255,0.6)
}
.global_header__announcement__anchor_signifier__icon svg {
  height: .65rem;
  width: .65rem
}
.global_header__announcement__anchor_signifier__text {
  line-height: 1.1;
  color: #fff
}
.global_header__inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1.618rem;
  padding: 1.618rem;
  padding-bottom: 0
}
@media only screen and (min-width:881px) {
  .global_header__inner {
    justify-content: space-between
  }
}
.global_header__logo_anchor {
  flex: 0 0 auto;
  display: flex
}
.global_header .logo_image {
  will-change: fill;
  transition-property: fill;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  width: 6rem
}
.global_header .logo_image .first_slice {
  fill: #ffecd1
}
.global_header .logo_image .second_slice {
  fill: #ffb34f
}
.global_header__navigation {
  will-change: transform;
  transition-property: transform;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  flex: 0 1 auto;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: .5393333333333333rem;
  background-color: #040f25;
  padding: .26966666666666667rem;
  padding-left: 1.077588rem
}
@media only screen and (max-width:880px) {
  .global_header__navigation {
    transform: translateX(-100%);
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }
}
@media only screen and (min-width:881px) {
  .global_header__navigation {
    display: flex
  }
}
@media only screen and (min-width:881px) {
  .global_header__navigation {
    flex-direction: row
  }
}
@media only screen and (min-width:881px) {
  .global_header__navigation {
    justify-content: flex-end
  }
}
@media only screen and (min-width:881px) {
  .global_header__navigation {
    gap: 1.077588rem;
    border-radius: 8px
  }
}
.global_header__navigation.global_header__navigation--is_visible {
  animation: global-navigation-display .28s forwards;
  display: flex
}
.global_header__navigation__close_button {
  position: absolute;
  z-index: auto;
  top: 1.618rem;
  right: 1.618rem;
  display: flex;
  height: 1.618rem;
  width: 1.618rem;
  color: #fff
}
@media only screen and (min-width:881px) {
  .global_header__navigation__close_button {
    display: none
  }
}
.global_header__navigation__close_button svg {
  height: 1.618rem;
  width: 1.618rem
}
.global_header__navigation__close_button svg path {
  stroke: currentColor
}
.global_header__navigation__list {
  display: flex;
  flex-direction: column;
  align-items: center
}
@media only screen and (min-width:881px) {
  .global_header__navigation__list {
    flex-direction: row
  }
}
@media only screen and (max-width:880px) {
  .global_header__navigation__list {
    width: 15rem
  }
}
.global_header__navigation__item {
  flex: 0 0 auto
}
@media only screen and (max-width:880px) {
  .global_header__navigation__item {
    width: 100%;
    padding: .809rem 0
  }
}
@media only screen and (max-width:880px) {
  .global_header__navigation__item:not(:last-child) {
    border-bottom: 1px solid #0a1b3e
  }
}
@media only screen and (min-width:881px) {
  .global_header__navigation__item + .global_header__navigation__item {
    margin-left: 1.077588rem
  }
}
.global_header__navigation__anchor {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  display: block;
  line-height: 1.1;
  transform: translateY(.1em);
  color: #fff
}
@media only screen and (max-width:880px) {
  .global_header__navigation__anchor {
    font-size: 1.2544rem;
    font-size: var(--fz-ratio-power--two)
  }
}
.global_header__navigation__anchor:active,
.global_header__navigation__anchor:focus,
.global_header__navigation__anchor:hover {
  color: rgba(255,255,255,0.8)
}
.global_header__navigation__anchor.global_header__navigation__anchor--is_active {
  position: relative;
  z-index: auto
}
.global_header__navigation__anchor.global_header__navigation__anchor--is_active::after {
  content: "";
  position: absolute;
  z-index: auto;
  right: 0;
  bottom: calc(-0.539333333333333rem - 2px);
  left: 0;
  height: 2px;
  background-color: rgba(255,255,255,0.8)
}
@media only screen and (max-width:880px) and (min-width:581px) {
  .global_header__navigation__anchor {
    font-size: 1.3225rem;
    font-size: var(--fz-ratio-power--two)
  }
}
@media only screen and (max-width:880px) and (min-width:881px) {
  .global_header__navigation__anchor {
    font-size: 1.3924rem;
    font-size: var(--fz-ratio-power--two)
  }
}
.global_header__navigation__priority_anchor {
  will-change: background-color,border-color;
  transition-property: background-color,border-color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5393333333333333rem;
  border: 1px solid #0f45b2;
  border-radius: 8px;
  background-color: #0a1b3e;
  padding: .5393333333333333rem 1.2135rem .5393333333333333rem 1.077588rem;
  line-height: 1.1;
  color: #fff
}
@media only screen and (max-width:880px) {
  .global_header__navigation__priority_anchor {
    width: 15rem
  }
}
@media only screen and (max-width:880px) {
  .global_header__navigation__priority_anchor {
    text-align: center
  }
}
.global_header__navigation__priority_anchor:active,
.global_header__navigation__priority_anchor:focus,
.global_header__navigation__priority_anchor:hover {
  border-color: #0036a1;
  background-color: #040f25
}
.global_header__navigation__priority_anchor__icon {
  flex: 0 0 auto;
  display: flex
}
.global_header__navigation__priority_anchor__icon svg {
  height: .65rem;
  width: .65rem
}
.global_header__navigation__priority_anchor__text {
  flex: 0 0 auto;
  display: block;
  line-height: 1.1;
  transform: translateY(.05em)
}
@media only screen and (max-width:880px) {
  .global_header__navigation__priority_anchor__text {
    font-size: 1.2544rem;
    font-size: var(--fz-ratio-power--two)
  }
}
@media only screen and (min-width:881px) {
  .global_header__navigation__priority_anchor__text {
    transform: translateY(.1em)
  }
}
@media only screen and (max-width:880px) and (min-width:581px) {
  .global_header__navigation__priority_anchor__text {
    font-size: 1.3225rem;
    font-size: var(--fz-ratio-power--two)
  }
}
@media only screen and (max-width:880px) and (min-width:881px) {
  .global_header__navigation__priority_anchor__text {
    font-size: 1.3924rem;
    font-size: var(--fz-ratio-power--two)
  }
}
.global_header__navigation__social__list {
  flex: 0 0 auto;
  display: block;
  width: 15rem
}
@media only screen and (min-width:881px) {
  .global_header__navigation__social__list {
    display: none
  }
}
.global_header__navigation__social__item {
  display: block;
  line-height: 1.45;
  color: #fff
}
@media only screen and (max-width:880px) {
  .global_header__navigation__social__item:not(:last-child) {
    border-bottom: 1px solid #0a1b3e
  }
}
.global_header__navigation__social__anchor {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  display: block;
  padding: .809rem 0;
  line-height: 1.1;
  transform: translateY(.1em);
  font-size: 1.2544rem;
  font-size: var(--fz-ratio-power--two);
  color: rgba(255,255,255,0.8)
}
@media only screen and (min-width:581px) {
  .global_header__navigation__social__anchor {
    font-size: 1.3225rem;
    font-size: var(--fz-ratio-power--two)
  }
}
@media only screen and (min-width:881px) {
  .global_header__navigation__social__anchor {
    font-size: 1.3924rem;
    font-size: var(--fz-ratio-power--two)
  }
}
.global_header__navigation__social__anchor:active,
.global_header__navigation__social__anchor:focus,
.global_header__navigation__social__anchor:hover {
  color: #fff
}
.global_header__navigation__social__text {
  flex: 0 0 auto
}
.global_header__priority_anchor {
  will-change: background-color;
  transition-property: background-color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5393333333333333rem;
  margin-left: auto;
  border-radius: 8px;
  border: 1px solid #0f45b2;
  background-color: #0a1b3e;
  padding: .5393333333333333rem 1.2135rem .5393333333333333rem 1.077588rem;
  line-height: 1.1;
  color: #fff
}
@media only screen and (min-width:881px) {
  .global_header__priority_anchor {
    display: none
  }
}
@media only screen and (max-width:880px) {
  .global_header__priority_anchor {
    text-align: center
  }
}
.global_header__priority_anchor:active,
.global_header__priority_anchor:focus,
.global_header__priority_anchor:hover {
  background-color: #040f25
}
.global_header__priority_anchor__icon {
  flex: 0 0 auto;
  display: flex
}
.global_header__priority_anchor__icon svg {
  height: .809rem;
  width: .809rem
}
.global_header__priority_anchor__text {
  flex: 0 0 auto;
  display: block;
  line-height: 1.1;
  transform: translateY(.05em)
}
@media only screen and (max-width:880px) {
  .global_header__priority_anchor__text {
    font-size: 1.2544rem;
    font-size: var(--fz-ratio-power--two)
  }
}
@media only screen and (max-width:880px) and (min-width:581px) {
  .global_header__priority_anchor__text {
    font-size: 1.3225rem;
    font-size: var(--fz-ratio-power--two)
  }
}
@media only screen and (max-width:880px) and (min-width:881px) {
  .global_header__priority_anchor__text {
    font-size: 1.3924rem;
    font-size: var(--fz-ratio-power--two)
  }
}
.global_header__navigation_open_button {
  flex: 0 0 auto;
  display: flex;
  border-radius: 8px;
  background-color: #000510;
  padding: .5393333333333333rem;
  color: #ffad41
}
@media only screen and (min-width:881px) {
  .global_header__navigation_open_button {
    display: none
  }
}
.global_header__navigation_open_button svg {
  height: 1.618rem;
  width: 1.618rem
}
.local_header {
  position: relative;
  z-index: auto;
  width: 100%;
  max-width: 100%;
  background-image: linear-gradient(to bottom,transparent,#040f25);
  border-bottom: 2px solid #000510
}
.local_header__inner {
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1480px;
  padding: 4.854rem 0
}
.local_header__inner--has_skip_anchor {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1.618rem
}
.local_header__text {
  flex: 1 1 auto
}
.local_header__return_anchor {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5393333333333333rem;
  color: #fff
}
.local_header__return_anchor:active .local_header__return_anchor__icon,
.local_header__return_anchor:focus .local_header__return_anchor__icon,
.local_header__return_anchor:hover .local_header__return_anchor__icon {
  transform: translateX(-.20225rem)
}
.local_header__return_anchor__icon {
  will-change: color,transform;
  transition-property: color,transform;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  display: flex;
  flex: 0 0 auto;
  height: .809rem;
  width: .809rem;
  color: rgba(255,255,255,0.8)
}
.local_header__return_anchor__text {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  flex: 1 1 auto;
  display: block;
  line-height: 1.1;
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one)
}
@media only screen and (min-width:581px) {
  .local_header__return_anchor__text {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  .local_header__return_anchor__text {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
.local_header__heading {
  max-width: 100%;
  background-clip: text;
  background-image: linear-gradient(to top right,#ffad41,#ffecd1);
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  line-height: 1.4;
  font-size: 1.762341683200001rem;
  font-size: var(--fz-ratio-power--five);
  color: #ffad41
}
* + .local_header__heading {
  margin-top: .4045rem
}
@media only screen and (min-width:581px) {
  .local_header__heading {
    font-size: 2.011357187499999rem;
    font-size: var(--fz-ratio-power--five)
  }
}
@media only screen and (min-width:881px) {
  .local_header__heading {
    font-size: 2.2877577568rem;
    font-size: var(--fz-ratio-power--five)
  }
}
.local_header__skip_anchor {
  position: relative;
  z-index: auto;
  flex: 0 0 auto;
  display: flex;
  height: 5rem;
  width: 5rem;
  color: #fff
}
.local_header__skip_anchor .apply_now {
  position: relative;
  z-index: auto;
  animation: rotation 14s infinite linear
}
.local_header__skip_anchor .apply_now__background {
  color: #0a1b3e
}
.local_header__skip_anchor .apply_now__text {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out
}
.local_header__skip_anchor .caret_down {
  position: absolute;
  z-index: 10;
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  top: 1.8rem;
  right: 1.7rem;
  height: 1.618rem;
  width: 1.618rem;
  color: #fff
}
.no_results_notice {
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1480px;
  padding: 3.236rem 0
}
.no_results_notice__text {
  display: block;
  width: 100%;
  max-width: 75ch;
  font-family: "brown__regular",sans-serif;
  font-size: 1rem;
  line-height: 1.618;
  letter-spacing: -.04em
}
.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.077588rem;
  width: 100%;
  max-width: 100%
}
.pagination:last-child {
  padding-bottom: 3.236rem
}
@media only screen and (min-width:781px) {
  .pagination:last-child {
    padding-bottom: 4.854rem
  }
}
.pagination__direction {
  flex: 0 0 auto
}
.pagination__direction__anchor {
  display: flex
}
.pagination__direction__anchor svg {
  height: 1.077588rem;
  width: 1.077588rem;
  color: #fff
}
.pagination__current {
  flex: 0 0 auto
}
.form_component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 30rem
}
* + .form_component {
  margin-top: 1.618rem
}
.form_component > * {
  flex: 0 0 auto
}
.form_component input[readonly="true"] {
  cursor: not-allowed;
  border-color: #232e43;
  background-color: #7b879d
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 50px #fff inset
}
.form_component__label {
  position: relative;
  z-index: 20;
  display: block;
  border-radius: 8px;
  padding: .35rem .6rem;
  line-height: 1.1
}
.form_component__label::before {
  content: "";
  will-change: transform;
  transition-property: transform;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  position: absolute;
  z-index: 20;
  transform: scale(0);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1.2rem;
  border-radius: 8px 8px 0 0;
  background-color: #fff
}
.form_component__label::after {
  content: "";
  will-change: background-color,transform;
  transition-property: background-color,transform;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  position: absolute;
  z-index: 10;
  transform: scale(0);
  top: -2px;
  right: -2px;
  left: -2px;
  height: 1.1rem;
  border-radius: 8px 8px 0 0;
  background-color: #ffad41
}
.form_component__label__required {
  color: #ffb1b5
}
.form_component__label__text {
  line-height: 1.1;
  color: #7b879d
}
.form_component--email,
.form_component--number,
.form_component--password,
.form_component--text,
.form_component--url {
  position: relative;
  z-index: auto
}
.form_component--email .form_component__label,
.form_component--number .form_component__label,
.form_component--password .form_component__label,
.form_component--text .form_component__label,
.form_component--url .form_component__label {
  will-change: font-size,top;
  transition-property: font-size,top;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  pointer-events: none;
  cursor: text;
  isolation: isolate;
  position: absolute;
  z-index: auto;
  top: .8rem;
  left: .6rem
}
.form_component--email .form_component__label::after,
.form_component--number .form_component__label::after,
.form_component--password .form_component__label::after,
.form_component--text .form_component__label::after,
.form_component--url .form_component__label::after {
  background-color: #0f45b2
}
.form_component--email .form_component__label__text,
.form_component--number .form_component__label__text,
.form_component--password .form_component__label__text,
.form_component--text .form_component__label__text,
.form_component--url .form_component__label__text,
.form_component--email .form_component__label__required,
.form_component--number .form_component__label__required,
.form_component--password .form_component__label__required,
.form_component--text .form_component__label__required,
.form_component--url .form_component__label__required {
  position: relative;
  z-index: 20
}
.form_component__email,
.form_component__number,
.form_component__password,
.form_component__text,
.form_component__url {
  will-change: background-color,border-color;
  transition-property: background-color,border-color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  border: 2px solid #214183;
  padding: 1.077588rem 1.077588rem .809rem;
  background-color: #f6f7f9;
  line-height: 1.1;
  caret-color: transparent
}
.form_component__email::-webkit-input-placeholder,
.form_component__number::-webkit-input-placeholder,
.form_component__password::-webkit-input-placeholder,
.form_component__text::-webkit-input-placeholder,
.form_component__url::-webkit-input-placeholder {
  opacity: 0
}
.form_component__email:-moz-placeholder,
.form_component__number:-moz-placeholder,
.form_component__password:-moz-placeholder,
.form_component__text:-moz-placeholder,
.form_component__url:-moz-placeholder {
  opacity: 0
}
.form_component__email::-moz-placeholder,
.form_component__number::-moz-placeholder,
.form_component__password::-moz-placeholder,
.form_component__text::-moz-placeholder,
.form_component__url::-moz-placeholder {
  opacity: 0
}
.form_component__email:-ms-input-placeholder,
.form_component__number:-ms-input-placeholder,
.form_component__password:-ms-input-placeholder,
.form_component__text:-ms-input-placeholder,
.form_component__url:-ms-input-placeholder {
  opacity: 0
}
.form_component__email:hover,
.form_component__number:hover,
.form_component__password:hover,
.form_component__text:hover,
.form_component__url:hover {
  border-color: #214183;
  background-color: #fff
}
.form_component__email:focus,
.form_component__number:focus,
.form_component__password:focus,
.form_component__text:focus,
.form_component__url:focus {
  outline: none;
  border-color: #0f45b2;
  background-color: #fff
}
.form_component__email:focus,
.form_component__number:focus,
.form_component__password:focus,
.form_component__text:focus,
.form_component__url:focus {
  caret-color: #689aff
}
.form_component__email:not(:focus).form_component__email:not(:placeholder-shown) ~ .form_component__label::before,
.form_component__number:not(:focus).form_component__number:not(:placeholder-shown) ~ .form_component__label::before,
.form_component__password:not(:focus).form_component__password:not(:placeholder-shown) ~ .form_component__label::before,
.form_component__text:not(:focus).form_component__text:not(:placeholder-shown) ~ .form_component__label::before,
.form_component__url:not(:focus).form_component__url:not(:placeholder-shown) ~ .form_component__label::before {
  background-color: #f6f7f9
}
.form_component__email:focus ~ .form_component__label,
.form_component__number:focus ~ .form_component__label,
.form_component__password:focus ~ .form_component__label,
.form_component__text:focus ~ .form_component__label,
.form_component__url:focus ~ .form_component__label,
.form_component__email:not(:placeholder-shown) ~ .form_component__label,
.form_component__number:not(:placeholder-shown) ~ .form_component__label,
.form_component__password:not(:placeholder-shown) ~ .form_component__label,
.form_component__text:not(:placeholder-shown) ~ .form_component__label,
.form_component__url:not(:placeholder-shown) ~ .form_component__label {
  top: -.9rem;
  left: .55rem;
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one)
}
@media only screen and (min-width:581px) {
  .form_component__email:focus ~ .form_component__label,
  .form_component__number:focus ~ .form_component__label,
  .form_component__password:focus ~ .form_component__label,
  .form_component__text:focus ~ .form_component__label,
  .form_component__url:focus ~ .form_component__label,
  .form_component__email:not(:placeholder-shown) ~ .form_component__label,
  .form_component__number:not(:placeholder-shown) ~ .form_component__label,
  .form_component__password:not(:placeholder-shown) ~ .form_component__label,
  .form_component__text:not(:placeholder-shown) ~ .form_component__label,
  .form_component__url:not(:placeholder-shown) ~ .form_component__label {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  .form_component__email:focus ~ .form_component__label,
  .form_component__number:focus ~ .form_component__label,
  .form_component__password:focus ~ .form_component__label,
  .form_component__text:focus ~ .form_component__label,
  .form_component__url:focus ~ .form_component__label,
  .form_component__email:not(:placeholder-shown) ~ .form_component__label,
  .form_component__number:not(:placeholder-shown) ~ .form_component__label,
  .form_component__password:not(:placeholder-shown) ~ .form_component__label,
  .form_component__text:not(:placeholder-shown) ~ .form_component__label,
  .form_component__url:not(:placeholder-shown) ~ .form_component__label {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
.form_component__email:focus ~ .form_component__label::before,
.form_component__number:focus ~ .form_component__label::before,
.form_component__password:focus ~ .form_component__label::before,
.form_component__text:focus ~ .form_component__label::before,
.form_component__url:focus ~ .form_component__label::before,
.form_component__email:not(:placeholder-shown) ~ .form_component__label::before,
.form_component__number:not(:placeholder-shown) ~ .form_component__label::before,
.form_component__password:not(:placeholder-shown) ~ .form_component__label::before,
.form_component__text:not(:placeholder-shown) ~ .form_component__label::before,
.form_component__url:not(:placeholder-shown) ~ .form_component__label::before,
.form_component__email:focus ~ .form_component__label::after,
.form_component__number:focus ~ .form_component__label::after,
.form_component__password:focus ~ .form_component__label::after,
.form_component__text:focus ~ .form_component__label::after,
.form_component__url:focus ~ .form_component__label::after,
.form_component__email:not(:placeholder-shown) ~ .form_component__label::after,
.form_component__number:not(:placeholder-shown) ~ .form_component__label::after,
.form_component__password:not(:placeholder-shown) ~ .form_component__label::after,
.form_component__text:not(:placeholder-shown) ~ .form_component__label::after,
.form_component__url:not(:placeholder-shown) ~ .form_component__label::after {
  transform: scale(1)
}
.form_component__email:focus ~ .form_component__label::after,
.form_component__number:focus ~ .form_component__label::after,
.form_component__password:focus ~ .form_component__label::after,
.form_component__text:focus ~ .form_component__label::after,
.form_component__url:focus ~ .form_component__label::after,
.form_component__email:not(:placeholder-shown) ~ .form_component__label::after,
.form_component__number:not(:placeholder-shown) ~ .form_component__label::after,
.form_component__password:not(:placeholder-shown) ~ .form_component__label::after,
.form_component__text:not(:placeholder-shown) ~ .form_component__label::after,
.form_component__url:not(:placeholder-shown) ~ .form_component__label::after {
  background-color: #0f45b2
}
.form_component__email:not(:placeholder-shown):not(:focus),
.form_component__number:not(:placeholder-shown):not(:focus),
.form_component__password:not(:placeholder-shown):not(:focus),
.form_component__text:not(:placeholder-shown):not(:focus),
.form_component__url:not(:placeholder-shown):not(:focus),
.form_component__email:-webkit-autofill,
.form_component__number:-webkit-autofill,
.form_component__password:-webkit-autofill,
.form_component__text:-webkit-autofill,
.form_component__url:-webkit-autofill {
  border-color: #0036a1
}
.form_component__email:not(:placeholder-shown):not(:focus) ~ .form_component__label,
.form_component__number:not(:placeholder-shown):not(:focus) ~ .form_component__label,
.form_component__password:not(:placeholder-shown):not(:focus) ~ .form_component__label,
.form_component__text:not(:placeholder-shown):not(:focus) ~ .form_component__label,
.form_component__url:not(:placeholder-shown):not(:focus) ~ .form_component__label,
.form_component__email:-webkit-autofill ~ .form_component__label,
.form_component__number:-webkit-autofill ~ .form_component__label,
.form_component__password:-webkit-autofill ~ .form_component__label,
.form_component__text:-webkit-autofill ~ .form_component__label,
.form_component__url:-webkit-autofill ~ .form_component__label {
  top: -.9rem
}
.form_component__email:not(:placeholder-shown):not(:focus) ~ .form_component__label::before,
.form_component__number:not(:placeholder-shown):not(:focus) ~ .form_component__label::before,
.form_component__password:not(:placeholder-shown):not(:focus) ~ .form_component__label::before,
.form_component__text:not(:placeholder-shown):not(:focus) ~ .form_component__label::before,
.form_component__url:not(:placeholder-shown):not(:focus) ~ .form_component__label::before,
.form_component__email:-webkit-autofill ~ .form_component__label::before,
.form_component__number:-webkit-autofill ~ .form_component__label::before,
.form_component__password:-webkit-autofill ~ .form_component__label::before,
.form_component__text:-webkit-autofill ~ .form_component__label::before,
.form_component__url:-webkit-autofill ~ .form_component__label::before,
.form_component__email:not(:placeholder-shown):not(:focus) ~ .form_component__label::after,
.form_component__number:not(:placeholder-shown):not(:focus) ~ .form_component__label::after,
.form_component__password:not(:placeholder-shown):not(:focus) ~ .form_component__label::after,
.form_component__text:not(:placeholder-shown):not(:focus) ~ .form_component__label::after,
.form_component__url:not(:placeholder-shown):not(:focus) ~ .form_component__label::after,
.form_component__email:-webkit-autofill ~ .form_component__label::after,
.form_component__number:-webkit-autofill ~ .form_component__label::after,
.form_component__password:-webkit-autofill ~ .form_component__label::after,
.form_component__text:-webkit-autofill ~ .form_component__label::after,
.form_component__url:-webkit-autofill ~ .form_component__label::after {
  transform: scale(1)
}
.form_component__email:not(:placeholder-shown):not(:focus) ~ .form_component__label::after,
.form_component__number:not(:placeholder-shown):not(:focus) ~ .form_component__label::after,
.form_component__password:not(:placeholder-shown):not(:focus) ~ .form_component__label::after,
.form_component__text:not(:placeholder-shown):not(:focus) ~ .form_component__label::after,
.form_component__url:not(:placeholder-shown):not(:focus) ~ .form_component__label::after,
.form_component__email:-webkit-autofill ~ .form_component__label::after,
.form_component__number:-webkit-autofill ~ .form_component__label::after,
.form_component__password:-webkit-autofill ~ .form_component__label::after,
.form_component__text:-webkit-autofill ~ .form_component__label::after,
.form_component__url:-webkit-autofill ~ .form_component__label::after {
  background-color: #0f45b2
}
.form_component__email:not(:placeholder-shown):not(:focus) ~ .form_component__label .form_component__label__text,
.form_component__number:not(:placeholder-shown):not(:focus) ~ .form_component__label .form_component__label__text,
.form_component__password:not(:placeholder-shown):not(:focus) ~ .form_component__label .form_component__label__text,
.form_component__text:not(:placeholder-shown):not(:focus) ~ .form_component__label .form_component__label__text,
.form_component__url:not(:placeholder-shown):not(:focus) ~ .form_component__label .form_component__label__text,
.form_component__email:-webkit-autofill ~ .form_component__label .form_component__label__text,
.form_component__number:-webkit-autofill ~ .form_component__label .form_component__label__text,
.form_component__password:-webkit-autofill ~ .form_component__label .form_component__label__text,
.form_component__text:-webkit-autofill ~ .form_component__label .form_component__label__text,
.form_component__url:-webkit-autofill ~ .form_component__label .form_component__label__text {
  color: #7b879d
}
.form_component__email ~ .form_component__label::after,
.form_component__number ~ .form_component__label::after,
.form_component__password ~ .form_component__label::after,
.form_component__text ~ .form_component__label::after,
.form_component__url ~ .form_component__label::after {
  background-color: #0f45b2
}
html[data-focus-source="key"] .form_component__email:focus,
html[data-focus-source="key"] .form_component__number:focus,
html[data-focus-source="key"] .form_component__password:focus,
html[data-focus-source="key"] .form_component__text:focus,
html[data-focus-source="key"] .form_component__url:focus {
  z-index: auto;
  outline: none
}
.form_component__number {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  padding-right: 2.427rem
}
.form_component--checkbox {
  grid-column: span 2
}
* + .form_component--checkbox {
  margin-top: 1.077588rem
}
.form_component--checkbox + .form_component--checkbox {
  margin-top: .5393333333333333rem
}
.form_component__simulated_checkbox {
  position: relative;
  z-index: 0;
  display: flex;
  gap: .809rem;
  width: 100%;
  max-width: 100%
}
.form_component__simulated_checkbox .form_component__help_text {
  right: 0
}
.form_component__simulated_checkbox .form_component__help_text__button__icon {
  color: #0f45b2
}
.form_component__simulated_checkbox__button {
  will-change: background-color;
  transition-property: background-color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  position: relative;
  z-index: auto;
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-start;
  height: 1.618rem;
  width: 2.8315rem;
  border-radius: 32px;
  padding: .26966666666666667rem;
  background-color: #8f9ebc
}
.form_component__checkbox:checked + .form_component__simulated_checkbox__button {
  background-color: #0f45b2
}
.form_component__checkbox:checked + .form_component__simulated_checkbox__button .form_component__simulated_checkbox__button__selector {
  left: calc(100% - 1.348333333333333rem)
}
.form_component__simulated_checkbox__button__selector {
  will-change: left;
  transition-property: left;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  position: absolute;
  z-index: auto;
  top: .26966666666666667rem;
  left: .26966666666666667rem;
  display: block;
  height: calc(100% - 0.539333333333333rem);
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: #fff
}
.form_component__simulated_checkbox__label {
  flex: 1 1 auto;
  transform: translateY(.1em);
  display: block;
  line-height: 1.45;
  color: rgba(255,255,255,0.8)
}
.form_component__simulated_checkbox__label--has_anchor {
  position: relative;
  z-index: 10;
  cursor: pointer
}
.form_component__simulated_checkbox__label a {
  position: relative;
  z-index: 20
}
.form_component__simulated_checkbox__label a,
.form_component__simulated_checkbox__label a:visited {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  color: #0f45b2
}
.form_component__simulated_checkbox__label a:active,
.form_component__simulated_checkbox__label a:visited:active,
.form_component__simulated_checkbox__label a:focus,
.form_component__simulated_checkbox__label a:visited:focus,
.form_component__simulated_checkbox__label a:hover,
.form_component__simulated_checkbox__label a:visited:hover {
  color: #0036a1
}
.form_component__checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 1.618rem;
  bottom: 0;
  left: 0;
  margin: 0;
  cursor: pointer
}
.form_component--date {
  flex-direction: row;
  align-items: stretch
}
.form_component__date {
  cursor: pointer;
  color: #232e43
}
.form_component--file {
  width: 100%;
  max-width: 100%
}
.form_component--file::-webkit-input-placeholder {
  opacity: 0
}
.form_component--file:-moz-placeholder {
  opacity: 0
}
.form_component--file::-moz-placeholder {
  opacity: 0
}
.form_component--file:-ms-input-placeholder {
  opacity: 0
}
.form_component__file_display_label {
  display: block;
  margin-top: .3236rem;
  color: rgba(255,255,255,0.8)
}
.form_component__file_display_label:first-child {
  margin-top: -.3236rem
}
.form_component__file {
  overflow: hidden;
  opacity: 0;
  height: 1px;
  width: 1px
}
html[data-focus-source="key"] .form_component__file:focus + .form_component .further_anchor {
  z-index: 100;
  outline-offset: 4px;
  outline: 2px dashed #ffad41;
  overflow: visible
}
.form_component * + .anchor_further {
  margin-top: 0
}
.form_component__selected_file_information {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: .5393333333333333rem
}
* + .form_component__selected_file_information {
  margin-top: .5393333333333333rem
}
.form_component__selected_file_information:not(:last-child) {
  margin-bottom: .5393333333333333rem
}
.form_component__selected_file_information__file_name {
  flex: 1 1 auto;
  font-weight: normal;
  letter-spacing: -.02em;
  color: rgba(255,255,255,0.8)
}
.form_component__selected_file_information__clear_button {
  flex: 0 0 auto;
  color: #dc4048
}
.form_component__selected_file_information__clear_button:not([hidden]) {
  display: inline-block
}
.form_component__selected_file_information__clear_button svg {
  height: .809rem;
  width: .809rem
}
.form_component__selected_file_information__text {
  color: rgba(255,255,255,0.8)
}
#file_upload_size_error {
  display: none
}
.form_component__field_error {
  display: block;
  margin-top: .4045rem;
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one);
  line-height: 1.45;
  color: #dc4048
}
@media only screen and (min-width:581px) {
  .form_component__field_error {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  .form_component__field_error {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
.form_component__field_error:first-child {
  margin-top: -.3236rem
}
* + .form {
  margin-top: 1.618rem
}
.form__non_field_errors,
.form__non_field_errors__list {
  width: 100%;
  max-width: 100%
}
.form__non_field_errors__list {
  margin-bottom: 2.427rem;
  border-top: 2px solid #dc4048;
  border-bottom: 2px solid #dc4048;
  padding-top: .5393333333333333rem;
  padding-bottom: .5393333333333333rem
}
.form__non_field_errors__item {
  line-height: 1.45;
  color: #dc4048
}
* + .form__non_field_errors__item {
  margin-top: .5393333333333333rem
}
.form__field_to_validate:required:invalid:focus,
.form__field_to_validate:required:invalid:active {
  border-color: #dc4048
}
.form__field_to_validate:required:valid:focus,
.form__field_to_validate:required:valid:active {
  border-color: #09a981
}
.form__fields {
  width: 100%;
  max-width: 100%
}
.form .actions {
  justify-content: flex-end
}
.form * + .actions {
  margin-top: 1.618rem
}
.form_component__help_text {
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 1.077588rem;
  bottom: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  gap: .4rem;
  width: fit-content;
  max-width: calc(100% - 1.618rem);
  color: #7b879d
}
.form_component__field_error + .form_component__help_text {
  top: .85rem;
  bottom: unset;
  align-items: flex-start
}
.form_component__help_text:focus,
.form_component__help_text:hover {
  z-index: 20
}
.form_component__help_text__button {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  flex: 0 0 auto;
  pointer-events: all;
  height: 1.618rem;
  width: 1.618rem;
  color: #0f45b2
}
.form_component__help_text__button:focus,
.form_component__help_text__button:hover {
  color: #0036a1
}
.form_component__help_text__button:focus ~ .form_component__help_text__message,
.form_component__help_text__button:hover ~ .form_component__help_text__message {
  pointer-events: all;
  opacity: 1;
  transform: scale(1)
}
.form_component__help_text__button__icon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center
}
.form_component__help_text__message {
  will-change: opacity,transform;
  transition-property: opacity,transform;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  flex: 1 1 auto;
  transform: scale(.2);
  opacity: 0;
  display: flex;
  flex-direction: row;
  align-items: safe center;
  box-shadow: 0 2px 4px hsla(220,25%,65%,0.020000000000000018),0 1px 1px hsla(220,25%,65%,0.09999999999999998);
  border-radius: 8px;
  background-color: #040f25;
  padding: .5393333333333333rem .809rem
}
.form_component__help_text__text {
  display: block;
  line-height: 1.5;
  transform: translateY(.1em);
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: #fff
}
@media only screen and (min-width:581px) {
  .form_component__help_text__text {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  .form_component__help_text__text {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
.form_component__help_text__text li + li {
  margin-top: .3236rem
}
.form_component__radio_option {
  position: relative;
  z-index: auto;
  display: flex;
  width: 100%;
  max-width: 75ch
}
* + .form_component__radio_option {
  margin-top: .5393333333333333rem
}
.form_component__radio {
  cursor: pointer;
  z-index: 10;
  position: absolute;
  opacity: 0;
  margin: 0;
  border: none;
  height: 100%;
  width: 100%;
  padding: 0
}
.form_component__radio__label {
  cursor: pointer;
  position: relative;
  z-index: auto;
  display: inline-block;
  padding-left: calc(0.539333333333333rem + 20px);
  color: #7b879d
}
@media only screen and (max-width:780px) {
  .form_component__radio__label {
    padding-top: .20225rem
  }
}
.form_component__radio__label::before,
.form_component__radio__label::after {
  will-change: opacity,border-color,transform,scale;
  transition-property: opacity,border-color,transform,scale;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  content: "";
  position: absolute;
  display: inline-block
}
.form_component__radio__label::before {
  top: .20225rem;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #214183;
  background-color: #fff
}
html[data-focus-source="key"] .form_component__radio:focus + .form_component__radio__label::before {
  z-index: 100;
  outline-offset: 4px;
  outline: 2px dashed #ffad41;
  overflow: visible
}
.form_component__radio:checked + .form_component__radio__label::before,
.form_component__radio:focus:checked + .form_component__radio__label::before {
  border-color: #0036a1
}
.form_component__radio__label::after {
  transform: scale(.1);
  top: calc(0.20225rem + 5px);
  left: 5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: none;
  background-color: #0036a1
}
.form_component__radio + .form_component__radio__label::after {
  opacity: 0
}
.form_component__radio:checked + .form_component__radio__label::after {
  z-index: 100;
  opacity: 1;
  transform: scale(.8)
}
.form_component--select {
  position: relative;
  z-index: auto
}
* + .form_component--select {
  margin-top: 1.618rem
}
.form_component--select .form_component__help_text {
  display: none
}
.form_component--select .form_component__label {
  top: .65rem;
  left: .55rem
}
.form_component--select .form_component__label::after {
  background-color: #ffad41
}
.form_component--select .form_component__select__button.form_component__select__button--target_is_visible {
  border-radius: 16px 16px 0 0
}
.form_component--select.form_component--select--has_selection .form_component__label {
  top: -.85rem;
  left: .55rem;
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one)
}
@media only screen and (min-width:581px) {
  .form_component--select.form_component--select--has_selection .form_component__label {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  .form_component--select.form_component--select--has_selection .form_component__label {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
.form_component--select.form_component--select--has_selection .form_component__label::before,
.form_component--select.form_component--select--has_selection .form_component__label::after {
  transform: scale(1)
}
.form_component--select .form_component__select__button.form_component__select__button--target_is_visible .form_component__select__button__icon {
  transform: rotate(180deg)
}
.form_component--select--has_selection .form_component__label {
  border-color: #689aff
}
.form_component__select {
  display: none
}
.form_component__select__button {
  will-change: border-color,border-radius;
  transition-property: border-color,border-radius;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: .5393333333333333rem;
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  border: 2px solid #214183;
  padding: 1.077588rem 1.077588rem .809rem;
  background-color: #f6f7f9;
  line-height: 1.1
}
.form_component__select__button::-webkit-input-placeholder {
  opacity: 0
}
.form_component__select__button:-moz-placeholder {
  opacity: 0
}
.form_component__select__button::-moz-placeholder {
  opacity: 0
}
.form_component__select__button:-ms-input-placeholder {
  opacity: 0
}
.form_component__select__button:hover {
  border-color: #214183;
  background-color: #fff
}
.form_component__select__button:focus {
  outline: none;
  border-color: #0f45b2;
  background-color: #fff
}
.form_component__select__button:focus,
.form_component__select__button:hover {
  border-color: #f08800
}
html[data-focus-source="key"] .form_component__select__button:focus {
  z-index: auto;
  outline: none
}
html[data-focus-source="key"] .form_component__select__button:focus .form_component__select__button__icon {
  transform: rotate(180deg)
}
.form_component__select__button:focus + .form_component__label::after,
.form_component__select__button:hover + .form_component__label::after {
  background-color: #214183
}
.form_component__select__button__icon {
  will-change: transform;
  transition-property: transform;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  flex: 0 0 auto;
  display: flex;
  margin-top: -.2rem;
  color: #0f45b2
}
.form_component__select__button__icon svg {
  height: 1rem;
  width: 1rem
}
.form_component__select__button__text {
  will-change: opacity;
  transition-property: opacity;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  flex: 1 1 auto;
  display: block;
  text-align: left;
  opacity: 0;
  color: #232e43
}
.form_component__select__options {
  will-change: height,opacity;
  transition-property: height,opacity;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  position: absolute;
  z-index: 40;
  top: 100%;
  opacity: 0;
  overflow: hidden;
  height: 0;
  width: 100%;
  padding-bottom: 1px
}
.form_component__select__options.form_component__select__options--is_visible {
  opacity: 1;
  overflow: visible
}
.form_component__select__list {
  position: relative;
  z-index: 40;
  overflow: auto;
  box-shadow: 0 2px 4px hsla(220,25%,65%,0.020000000000000018),0 1px 1px hsla(220,25%,65%,0.09999999999999998);
  max-height: 22rem;
  border-radius: 0 0 16px 16px;
  border: 1px solid #f08800;
  border-top: none;
  padding: 0 1.077588rem 1.077588rem;
  background-color: #fff
}
.form_component__select__item {
  cursor: pointer;
  margin-top: 1.077588rem
}
.form_component__select__option {
  will-change: padding-left,padding-right;
  transition-property: padding-left,padding-right;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  position: relative;
  z-index: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  padding-right: .809rem;
  text-align: left;
  line-height: 1.45;
  color: #232e43
}
.form_component__select__option:focus,
.form_component__select__option:hover {
  padding-right: 0;
  padding-left: .809rem
}
.form_component__select__option:focus::before,
.form_component__select__option:hover::before {
  left: 0;
  background-color: #0f45b2
}
.form_component__select__option[data-value=""] {
  color: #9da3af
}
.form_component__select__option::before {
  content: "";
  will-change: background-color,left;
  transition-property: background-color,left;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  transition-delay: .07s,0s;
  transform: translateY(-50%);
  position: absolute;
  z-index: auto;
  top: 50%;
  left: -.4045rem;
  display: block;
  height: .4045rem;
  width: .4045rem;
  border-radius: 50%;
  background-color: #fff
}
.form_component__select__option span {
  display: block
}
.form_component__select__option__help_text {
  position: absolute;
  z-index: auto;
  top: .809rem;
  right: 1.077588rem;
  will-change: opacity,transform;
  transition-property: opacity,transform;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  transform: scale(.2);
  opacity: 0;
  display: flex;
  flex-direction: row;
  align-items: safe center;
  box-shadow: 0 2px 4px hsla(220,25%,65%,0.020000000000000018),0 1px 1px hsla(220,25%,65%,0.09999999999999998);
  border-radius: 0 8px 8px 0;
  border: 1px solid #f6f7f9;
  border-left: 2px solid #ffad41;
  background-color: #fff;
  padding: .5393333333333333rem .809rem;
  line-height: 1.5;
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one)
}
@media only screen and (min-width:581px) {
  .form_component__select__option__help_text {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  .form_component__select__option__help_text {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
.form_component__select__option__message {
  display: flex;
  align-items: flex-start;
  gap: .4045rem;
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: #7b879d
}
@media only screen and (min-width:581px) {
  .form_component__select__option__message {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  .form_component__select__option__message {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
.form_component__select__option__message__icon,
.form_component__select__option__message__text {
  display: block
}
.form_component__select__option__message__icon {
  flex: 0 0 auto;
  height: .5393333333333333rem;
  width: .5393333333333333rem
}
.form_component__select__option__message__text {
  flex: 1 1 auto
}
.form_component--textarea {
  position: relative;
  z-index: auto
}
.form_component--textarea .form_component__label {
  will-change: font-size,top;
  transition-property: font-size,top;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  pointer-events: none;
  cursor: text;
  isolation: isolate;
  position: absolute;
  z-index: auto;
  top: .8rem;
  left: .6rem
}
.form_component--textarea .form_component__label__text,
.form_component--textarea .form_component__label__required {
  position: relative;
  z-index: 20
}
.form_component__textarea {
  will-change: background-color,border-color;
  transition-property: background-color,border-color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  resize: none;
  margin: 0;
  height: 6.472rem;
  width: calc(100% - 2.156794rem);
  max-width: calc(100% - 2.156794rem);
  border: none;
  background-color: transparent;
  padding: 0;
  caret-color: transparent;
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  border: 2px solid #214183;
  padding: 1.077588rem 1.077588rem .809rem;
  background-color: #f6f7f9;
  line-height: 1.1;
  line-height: 1.618
}
.form_component__textarea::-webkit-input-placeholder {
  opacity: 0
}
.form_component__textarea:-moz-placeholder {
  opacity: 0
}
.form_component__textarea::-moz-placeholder {
  opacity: 0
}
.form_component__textarea:-ms-input-placeholder {
  opacity: 0
}
.form_component__textarea:hover {
  border-color: #214183;
  background-color: #fff
}
.form_component__textarea:focus {
  outline: none;
  border-color: #0f45b2;
  background-color: #fff
}
.form_component__textarea:focus {
  caret-color: #689aff
}
.form_component__textarea:not(:focus).form_component__textarea:not(:placeholder-shown) ~ .form_component__label::before {
  background-color: #f6f7f9
}
.form_component__textarea:focus ~ .form_component__label,
.form_component__textarea:not(:placeholder-shown) ~ .form_component__label {
  top: -.9rem;
  left: .55rem;
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one)
}
@media only screen and (min-width:581px) {
  .form_component__textarea:focus ~ .form_component__label,
  .form_component__textarea:not(:placeholder-shown) ~ .form_component__label {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  .form_component__textarea:focus ~ .form_component__label,
  .form_component__textarea:not(:placeholder-shown) ~ .form_component__label {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
.form_component__textarea:focus ~ .form_component__label::before,
.form_component__textarea:not(:placeholder-shown) ~ .form_component__label::before,
.form_component__textarea:focus ~ .form_component__label::after,
.form_component__textarea:not(:placeholder-shown) ~ .form_component__label::after {
  transform: scale(1)
}
.form_component__textarea:focus ~ .form_component__label::after,
.form_component__textarea:not(:placeholder-shown) ~ .form_component__label::after {
  background-color: #0f45b2
}
.form_component__textarea:not(:placeholder-shown):not(:focus),
.form_component__textarea:-webkit-autofill {
  border-color: #0f45b2
}
.form_component__textarea:not(:placeholder-shown):not(:focus) ~ .form_component__label,
.form_component__textarea:-webkit-autofill ~ .form_component__label {
  top: -.9rem
}
.form_component__textarea:not(:placeholder-shown):not(:focus) ~ .form_component__label::before,
.form_component__textarea:-webkit-autofill ~ .form_component__label::before,
.form_component__textarea:not(:placeholder-shown):not(:focus) ~ .form_component__label::after,
.form_component__textarea:-webkit-autofill ~ .form_component__label::after {
  transform: scale(1)
}
.form_component__textarea:not(:placeholder-shown):not(:focus) ~ .form_component__label::after,
.form_component__textarea:-webkit-autofill ~ .form_component__label::after {
  background-color: #0f45b2
}
.form_component__textarea:not(:placeholder-shown):not(:focus) ~ .form_component__label .form_component__label__text,
.form_component__textarea:-webkit-autofill ~ .form_component__label .form_component__label__text {
  color: #7b879d
}
.form_component__textarea ~ .form_component__label::after {
  background-color: #0f45b2
}
html[data-focus-source="key"] .form_component__textarea:focus {
  z-index: auto;
  outline: none
}
.about__team {
  border-bottom: 2px solid #0a1b3e;
  background-color: #040f25;
  padding-top: 6.472rem;
  padding-bottom: 6.472rem
}
.about__team__list {
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1480px;
  display: grid;
  grid-template-columns: repeat(1,1fr);
  gap: 1.618rem
}
* + .about__team__list {
  margin-top: 8.09rem
}
@media only screen and (min-width:581px) {
  .about__team__list {
    grid-template-columns: repeat(2,1fr)
  }
}
@media only screen and (min-width:981px) {
  .about__team__list {
    grid-template-columns: repeat(6,1fr)
  }
}
.about__team__item {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #08245d
}
@media only screen and (min-width:981px) {
  .about__team__item {
    grid-column: span 2
  }
}
@media only screen and (min-width:981px) {
  .about__team__item:nth-child(1),
  .about__team__item:nth-child(2) {
    justify-self: center
  }
}
@media only screen and (min-width:981px) {
  .about__team__item:nth-child(1) {
    grid-column: 2/4;
    margin-left: auto
  }
}
@media only screen and (min-width:981px) {
  .about__team__item:nth-child(2) {
    grid-column: 4/6;
    margin-right: auto
  }
}
.about__team__portrait_and_details {
  position: relative;
  z-index: auto;
  flex: 0 0 auto
}
.about__team__portrait_and_details::after {
  content: "";
  position: absolute;
  z-index: auto;
  right: 0;
  bottom: 0;
  left: 0;
  height: 8rem;
  background-image: linear-gradient(to bottom,hsla(220,82%,8%,0),#040f25)
}
.about__team__portrait {
  object-fit: cover
}
.about__team__name_and_role {
  position: absolute;
  z-index: 10;
  right: 1.618rem;
  bottom: .809rem;
  left: 1.618rem
}
.about__team__name,
.about__team__role,
.about__team__professional_biography {
  display: block
}
.about__team__name,
.about__team__role {
  color: #fff
}
.about__team__name {
  font-weight: normal;
  font-family: "brown__bold",sans-serif;
  letter-spacing: -.02em
}
.about__team__details {
  flex: 1 1 auto;
  width: 100%;
  max-width: 100%;
  background-image: linear-gradient(to right top,hsla(220,100%,3%,0.9),hsla(220,85%,20%,0.9)),url("data:image/svg+xml, %3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.85' numOctaves='2' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  padding: 1.618rem
}
.about__team__professional_biography {
  color: rgba(255,255,255,0.8)
}
.about__social__list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: .5393333333333333rem
}
* + .about__social__list {
  margin-top: 1.2135rem
}
.about__social__item {
  flex: 0 0 auto;
  display: flex
}
.about__social__anchor {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  display: flex;
  color: #fff
}
.about__social__anchor:focus,
.about__social__anchor:hover {
  color: #fff
}
.about__social__anchor svg {
  flex: 0 0 auto;
  height: 1.618rem;
  width: 1.618rem
}
.contact__form {
  border-radius: 8px;
  border: 1px solid #08245d;
  background-image: linear-gradient(to right top,hsla(220,100%,3%,0.9),hsla(220,85%,20%,0.9)),url("data:image/svg+xml, %3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.7' numOctaves='2' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  padding: 1.618rem
}
* + .contact__form {
  margin-top: 1.618rem
}
.contact__form .form__fields {
  display: flex;
  flex-direction: column;
  gap: 1.618rem
}
.contact__form * + .form_component {
  margin-top: 0
}
.contact__form .form_component {
  max-width: 100%
}
.contact__form .form_component__simulated_checkbox__label a,
.contact__form .form_component__simulated_checkbox__label a:visited {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  color: #fff
}
.contact__form .form_component__simulated_checkbox__label a:active,
.contact__form .form_component__simulated_checkbox__label a:visited:active,
.contact__form .form_component__simulated_checkbox__label a:focus,
.contact__form .form_component__simulated_checkbox__label a:visited:focus,
.contact__form .form_component__simulated_checkbox__label a:hover,
.contact__form .form_component__simulated_checkbox__label a:visited:hover {
  color: #fff
}
.contact__summary {
  margin: 0 auto;
  width: 40rem;
  max-width: 100%
}
.contact__summary a,
.contact__summary a:visited,
.contact__summary a:visited:visited {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  color: #0f45b2
}
.contact__summary a:active,
.contact__summary a:visited:active,
.contact__summary a:visited:visited:active,
.contact__summary a:focus,
.contact__summary a:visited:focus,
.contact__summary a:visited:visited:focus,
.contact__summary a:hover,
.contact__summary a:visited:hover,
.contact__summary a:visited:visited:hover {
  color: #0036a1
}
.home__introduction__inner {
  position: relative;
  z-index: auto;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1480px;
  padding-top: 4.854rem;
  padding-bottom: 4.854rem
}
.home__introduction__heading {
  background-clip: text;
  background-image: linear-gradient(to top right,#ffad41,#ffecd1);
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  transform: translateX(-.01em);
  font-size: 1.973822685184001rem;
  font-size: var(--fz-ratio-power--six)
}
@media only screen and (min-width:581px) {
  .home__introduction__heading {
    font-size: 2.313060765624999rem;
    font-size: var(--fz-ratio-power--six)
  }
}
@media only screen and (min-width:881px) {
  .home__introduction__heading {
    font-size: 2.699554153023999rem;
    font-size: var(--fz-ratio-power--six)
  }
}
.home__introduction__summary {
  display: block;
  width: 45ch;
  max-width: 100%;
  text-shadow: 0 0 .4em #000510;
  line-height: 1.3;
  font-size: 1.404928rem;
  font-size: var(--fz-ratio-power--three);
  letter-spacing: -.04em;
  color: #fff
}
* + .home__introduction__summary {
  margin-top: 1.077588rem
}
@media only screen and (min-width:581px) {
  .home__introduction__summary {
    font-size: 1.520875rem;
    font-size: var(--fz-ratio-power--three)
  }
}
@media only screen and (min-width:881px) {
  .home__introduction__summary {
    font-size: 1.643032rem;
    font-size: var(--fz-ratio-power--three)
  }
}
* + .home__platform__benefits {
  margin-top: 3.236rem
}
.home__platform__benefits__list {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.618rem
}
@media only screen and (min-width:481px) {
  .home__platform__benefits__list {
    flex-direction: row
  }
}
.home__platform__benefits__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border-radius: 16px;
  border: 1px solid #e8ebf0;
  background-image: linear-gradient(to top right,#f1f2f4,#fff,#f1f2f4)
}
@media only screen and (min-width:481px) {
  .home__platform__benefits__item {
    flex: 0 1 33.333%
  }
}
@media only screen and (max-width:480px) {
  .home__platform__benefits__item {
    width: 60%
  }
}
.home__platform__benefits__item:first-child svg {
  width: 94%
}
@media only screen and (max-width:480px) {
  .home__platform__benefits__item:nth-child(2) {
    margin-right: auto;
    margin-left: auto
  }
}
.home__platform__benefits__item:nth-child(2) svg {
  width: 125%
}
@media only screen and (max-width:480px) {
  .home__platform__benefits__item:last-child {
    margin-left: auto
  }
}
.home__platform__benefits__item:last-child svg {
  top: 0;
  left: 5%;
  width: 90%
}
.home__platform__benefits__item svg {
  height: unset;
  max-height: unset;
  width: unset;
  max-width: unset
}
.home__platform__benefits__icon {
  position: relative;
  z-index: auto;
  height: 9rem;
  width: 100%;
  max-width: 100%
}
.home__platform__benefits__icon svg {
  position: absolute;
  z-index: auto;
  left: 0;
  bottom: 0;
  right: 0
}
.home__platform__benefits__name {
  padding: 0 1.077588rem .5393333333333333rem;
  font-size: 1.12rem;
  font-size: var(--fz-ratio-power--one);
  text-align: center;
  color: #232e43
}
@media only screen and (min-width:581px) {
  .home__platform__benefits__name {
    font-size: 1.15rem;
    font-size: var(--fz-ratio-power--one)
  }
}
@media only screen and (min-width:881px) {
  .home__platform__benefits__name {
    font-size: 1.18rem;
    font-size: var(--fz-ratio-power--one)
  }
}
.insight_category__list {
  display: grid;
  grid-template-columns: repeat(1,1fr);
  gap: 1.618rem;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 2220px;
  padding-top: 6.472rem;
  padding-bottom: 6.472rem
}
@media only screen and (min-width:681px) {
  .insight_category__list {
    grid-template-columns: repeat(2,1fr)
  }
}
@media only screen and (min-width:1081px) {
  .insight_category__list {
    grid-template-columns: repeat(3,1fr)
  }
}
@media only screen and (min-width:1881px) {
  .insight_category__list {
    grid-template-columns: repeat(4,1fr)
  }
}
.insight_category__item,
.insight_category__item__article,
.insight_category__item__anchor {
  display: flex;
  height: 100%;
  width: 100%
}
.insight_category__item__anchor {
  will-change: box-shadow,transform;
  transition-property: box-shadow,transform;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  flex-direction: column;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #08245d
}
.insight_category__item__image {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  overflow: hidden;
  min-height: 16rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: .5393333333333333rem
}
@media only screen and (max-width:680px) {
  .insight_category__item__image {
    height: 34vw
  }
}
.insight_category__item__image--uncropped {
  background-color: #040f25;
  background-size: auto 80%
}
.insight_category__item__text {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-image: linear-gradient(to right top,hsla(220,100%,3%,0.9),hsla(220,85%,20%,0.9)),url("data:image/svg+xml, %3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.6' numOctaves='2' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  padding: 1.077588rem
}
.insight_category__item__text * + .anchor_further {
  margin-top: auto
}
.insight_category__item__date_and_icon {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1.077588rem;
  width: 100%;
  max-width: 100%
}
.insight_category__item__date {
  display: inline-block;
  line-height: 1.1;
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: rgba(255,255,255,0.8)
}
@media only screen and (min-width:581px) {
  .insight_category__item__date {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  .insight_category__item__date {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
.insight_category__item__icon {
  flex: 0 0 auto;
  display: flex
}
.insight_category__item__icon svg {
  height: .9rem;
  width: .9rem;
  color: rgba(255,255,255,0.8)
}
.insight_category__item__icon svg.svg__arrow_right {
  height: .8rem;
  width: .8rem
}
.insight_category__item__heading {
  width: 100%;
  display: block;
  line-height: 1.45;
  font-family: "brown__bold",sans-serif;
  font-weight: normal;
  max-width: 60ch;
  letter-spacing: -.02em;
  font-size: 1.12rem;
  font-size: var(--fz-ratio-power--one)
}
* + .insight_category__item__heading {
  margin-top: .45rem
}
@media only screen and (min-width:581px) {
  .insight_category__item__heading {
    font-size: 1.15rem;
    font-size: var(--fz-ratio-power--one)
  }
}
@media only screen and (min-width:881px) {
  .insight_category__item__heading {
    font-size: 1.18rem;
    font-size: var(--fz-ratio-power--one)
  }
}
.reading_content * + .insight_category__item__heading {
  margin-top: 1.624rem;
  margin-top: var(--sp-ratio-power--one)
}
@media only screen and (min-width:581px) {
  .reading_content * + .insight_category__item__heading {
    margin-top: 1.6675rem;
    margin-top: var(--sp-ratio-power--one)
  }
}
@media only screen and (min-width:881px) {
  .reading_content * + .insight_category__item__heading {
    margin-top: 1.711rem;
    margin-top: var(--sp-ratio-power--one)
  }
}
.insight_category__item__summary {
  display: block;
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: rgba(255,255,255,0.8)
}
* + .insight_category__item__summary {
  margin-top: .809rem
}
@media only screen and (min-width:581px) {
  .insight_category__item__summary {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  .insight_category__item__summary {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
.insight_category__item__summary:not(:last-child) {
  margin-bottom: 1.077588rem
}
.insights__articles {
  padding-top: 6.472rem;
  padding-bottom: 6.472rem;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 2220px
}
.insights__featured__article,
.insights__featured__anchor {
  width: 100%;
  max-width: 100%
}
.insights__featured__anchor {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  gap: 1.077588rem;
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  border: 1px solid #0f45b2;
  background-image: linear-gradient(to right top,hsla(220,100%,3%,0.9),hsla(220,85%,20%,0.9)),url("data:image/svg+xml, %3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.6' numOctaves='2' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  padding: 1.618rem
}
@media only screen and (min-width:681px) {
  .insights__featured__anchor {
    flex-direction: row
  }
}
.insights__featured__image {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  overflow: hidden;
  border-radius: 8px;
  min-height: 14rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: .5393333333333333rem
}
@media only screen and (min-width:681px) {
  .insights__featured__image {
    flex: 0 0 40%
  }
}
@media only screen and (max-width:680px) {
  .insights__featured__image {
    min-height: 32vw
  }
}
.insights__featured__image--uncropped {
  background-color: #040f25;
  background-size: auto 80%
}
.insights__featured__text {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start
}
.insights__featured__text * + .anchor_further {
  margin-top: auto
}
.insights__featured__date_and_type {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1.077588rem;
  width: 100%;
  max-width: 100%
}
.insights__featured__date {
  display: inline-block;
  line-height: 1.1;
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: rgba(255,255,255,0.8)
}
@media only screen and (min-width:581px) {
  .insights__featured__date {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  .insights__featured__date {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
.insights__featured__type__list {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  gap: .26966666666666667rem
}
.insights__featured__type__item {
  display: inline-block;
  border-radius: 4px;
  background-color: #0a1b3e;
  padding: .1618rem .5393333333333333rem
}
.insights__featured__type__text {
  display: block;
  transform: translateY(.05em);
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: rgba(255,255,255,0.8)
}
@media only screen and (min-width:581px) {
  .insights__featured__type__text {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  .insights__featured__type__text {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
.insights__featured__heading {
  width: 100%
}
* + .insights__featured__heading {
  margin-top: .45rem
}
.insights__featured__summary {
  display: block;
  font-size: 1.12rem;
  font-size: var(--fz-ratio-power--one);
  color: rgba(255,255,255,0.8)
}
* + .insights__featured__summary {
  margin-top: .809rem
}
@media only screen and (min-width:581px) {
  .insights__featured__summary {
    font-size: 1.15rem;
    font-size: var(--fz-ratio-power--one)
  }
}
@media only screen and (min-width:881px) {
  .insights__featured__summary {
    font-size: 1.18rem;
    font-size: var(--fz-ratio-power--one)
  }
}
.insights__featured__summary:not(:last-child) {
  margin-bottom: 1.077588rem
}
* + .insights__category {
  margin-top: 3.236rem
}
@media only screen and (min-width:781px) {
  * + .insights__category {
    margin-top: 4.854rem
  }
}
.insights__category__heading_and_further {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1.618rem;
  width: 100%;
  max-width: 100%
}
.insights__category__heading_and_further * + .anchor_further {
  margin-top: 0;
  transform: translateY(.5em)
}
.insights__category__heading {
  transform: translateY(.15em);
  display: block;
  line-height: 1.45;
  font-family: "brown__bold",sans-serif;
  font-weight: normal;
  max-width: 37.5ch;
  letter-spacing: -.03em;
  font-size: 1.573519360000001rem;
  font-size: var(--fz-ratio-power--four);
  line-height: 1.1;
  color: #1a2232
}
@media only screen and (min-width:581px) {
  .insights__category__heading {
    font-size: 1.749006249999999rem;
    font-size: var(--fz-ratio-power--four)
  }
}
@media only screen and (min-width:881px) {
  .insights__category__heading {
    font-size: 1.93877776rem;
    font-size: var(--fz-ratio-power--four)
  }
}
.reading_content * + .insights__category__heading {
  margin-top: 2.281603072000001rem;
  margin-top: var(--sp-ratio-power--four)
}
@media only screen and (min-width:581px) {
  .reading_content * + .insights__category__heading {
    margin-top: 2.536059062499999rem;
    margin-top: var(--sp-ratio-power--four)
  }
}
@media only screen and (min-width:881px) {
  .reading_content * + .insights__category__heading {
    margin-top: 2.811227751999999rem;
    margin-top: var(--sp-ratio-power--four)
  }
}
.insights__category__content {
  display: flex;
  flex-direction: column;
  gap: 1.618rem;
  border-radius: 8px;
  border: 1px solid #0f45b2;
  background-image: linear-gradient(to right top,hsla(220,100%,3%,0.9),hsla(220,85%,20%,0.9)),url("data:image/svg+xml, %3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.6' numOctaves='2' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  padding: 1.618rem
}
* + .insights__category__content {
  margin-top: 1.618rem
}
@media only screen and (min-width:1181px) {
  .insights__category__content {
    flex-direction: row
  }
}
.insights__category__primary_list {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 1.618rem
}
@media only screen and (min-width:681px) {
  .insights__category__primary_list {
    flex-direction: row
  }
}
@media only screen and (min-width:681px) {
  .insights__category__primary_item {
    flex: 0 1 50%
  }
}
.insights__category__primary_item__article {
  width: 100%;
  max-width: 100%
}
.insights__category__primary_item,
.insights__category__primary_item__anchor {
  display: flex
}
.insights__category__primary_item__anchor {
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  gap: 1.077588rem;
  height: 100%;
  width: 100%;
  max-width: 100%
}
.insights__category__primary_item__image {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  overflow: hidden;
  border-radius: 8px;
  min-height: 12rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: .5393333333333333rem
}
@media only screen and (min-width:681px) {
  .insights__category__primary_item__image {
    flex: 0 0 40%
  }
}
@media only screen and (max-width:680px) {
  .insights__category__primary_item__image {
    height: 32vw
  }
}
.insights__category__primary_item__image--uncropped {
  background-color: #040f25;
  background-size: auto 80%
}
.insights__category__primary_item__text {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start
}
.insights__category__primary_item__text * + .anchor_further {
  margin-top: auto
}
.insights__category__primary_item__date_and_icon {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1.077588rem;
  width: 100%;
  max-width: 100%
}
.insights__category__primary_item__date {
  display: inline-block;
  line-height: 1.1;
  font-size: .7971938775510203rem;
  font-size: var(--fz-ratio-power--minus-two);
  color: rgba(255,255,255,0.8)
}
@media only screen and (min-width:581px) {
  .insights__category__primary_item__date {
    font-size: .7561436672967865rem;
    font-size: var(--fz-ratio-power--minus-two)
  }
}
@media only screen and (min-width:881px) {
  .insights__category__primary_item__date {
    font-size: .7181844297615629rem;
    font-size: var(--fz-ratio-power--minus-two)
  }
}
.insights__category__primary_item__icon {
  flex: 0 0 auto;
  display: flex
}
.insights__category__primary_item__icon svg {
  height: .9rem;
  width: .9rem;
  color: rgba(255,255,255,0.8)
}
.insights__category__primary_item__icon svg.svg__arrow_right {
  height: .8rem;
  width: .8rem
}
.insights__category__primary_item__heading {
  width: 100%;
  display: block;
  line-height: 1.45;
  font-family: "brown__bold",sans-serif;
  font-weight: normal;
  max-width: 60ch;
  letter-spacing: -.02em;
  font-size: 1.12rem;
  font-size: var(--fz-ratio-power--one)
}
* + .insights__category__primary_item__heading {
  margin-top: .4045rem
}
@media only screen and (min-width:581px) {
  .insights__category__primary_item__heading {
    font-size: 1.15rem;
    font-size: var(--fz-ratio-power--one)
  }
}
@media only screen and (min-width:881px) {
  .insights__category__primary_item__heading {
    font-size: 1.18rem;
    font-size: var(--fz-ratio-power--one)
  }
}
.reading_content * + .insights__category__primary_item__heading {
  margin-top: 1.624rem;
  margin-top: var(--sp-ratio-power--one)
}
@media only screen and (min-width:581px) {
  .reading_content * + .insights__category__primary_item__heading {
    margin-top: 1.6675rem;
    margin-top: var(--sp-ratio-power--one)
  }
}
@media only screen and (min-width:881px) {
  .reading_content * + .insights__category__primary_item__heading {
    margin-top: 1.711rem;
    margin-top: var(--sp-ratio-power--one)
  }
}
.insights__category__primary_item__summary {
  display: block;
  color: rgba(255,255,255,0.8)
}
* + .insights__category__primary_item__summary {
  margin-top: .4045rem
}
.insights__category__primary_item__summary:not(:last-child) {
  margin-bottom: 1.077588rem
}
.insights__category__secondary_list {
  flex: 0 0 22rem
}
.insights__category__secondary_item:not(:first-child) {
  border-top: 2px solid #040f25;
  padding-top: .8rem
}
.insights__category__secondary_item:not(:last-child) {
  padding-bottom: .7rem
}
.insights__category__secondary_item__anchor {
  display: block
}
.insights__category__secondary_item__heading {
  font-weight: normal;
  font-family: "brown__bold",sans-serif;
  letter-spacing: -.02em;
  font-size: 1rem
}
* + .insights__category__secondary_item__heading {
  margin-top: .5393333333333333rem
}
@media only screen and (max-width:1180px) {
  .insights__category__secondary_item__heading {
    max-width: 76ch;
    text-wrap: balance
  }
}
.insights__category__secondary_item__date_and_icon {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1.077588rem;
  width: 100%;
  max-width: 100%
}
.insights__category__secondary_item__date {
  display: inline-block;
  line-height: 1.1;
  font-size: .7971938775510203rem;
  font-size: var(--fz-ratio-power--minus-two);
  color: rgba(255,255,255,0.8)
}
@media only screen and (min-width:581px) {
  .insights__category__secondary_item__date {
    font-size: .7561436672967865rem;
    font-size: var(--fz-ratio-power--minus-two)
  }
}
@media only screen and (min-width:881px) {
  .insights__category__secondary_item__date {
    font-size: .7181844297615629rem;
    font-size: var(--fz-ratio-power--minus-two)
  }
}
.insights__category__secondary_item__icon {
  flex: 0 0 auto;
  display: flex
}
.insights__category__secondary_item__icon svg {
  height: .9rem;
  width: .9rem;
  color: rgba(255,255,255,0.8)
}
.insights__category__secondary_item__icon svg.svg__arrow_right {
  height: .8rem;
  width: .8rem
}
.job__details {
  position: relative;
  z-index: auto;
  background-color: #f6f7f9
}
.job__details__inner {
  padding-top: 6.472rem;
  padding-bottom: 6.472rem;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1480px
}
.job__details__summary_and_definitions {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3.236rem
}
@media only screen and (min-width:881px) {
  .job__details__summary_and_definitions {
    flex-direction: row
  }
}
* + .job__details__list {
  margin-top: 2.8315rem
}
* + .job__details__item {
  margin-top: .809rem
}
.job__summary {
  display: block;
  max-width: 66ch
}
.job__definitions__item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: .2rem;
  color: #7b879d
}
* + .job__definitions__item {
  margin-top: 1.618rem
}
.job__definitions__icon {
  flex: 0 0 auto;
  padding-top: .2rem
}
.job__definitions__icon svg {
  height: 1.077588rem;
  width: 1.077588rem;
  color: #9da3af
}
.job__definitions__text {
  flex: 1 1 auto
}
.job__definitions__term,
.job__definitions__description {
  display: block
}
.job__definitions__term::after {
  content: ":"
}
.job__definitions__description {
  display: block
}
.job__apply {
  border-bottom: 2px solid #0a1b3e;
  background-color: #040f25;
  padding-top: 6.472rem;
  padding-bottom: 6.472rem
}
.job__apply__heading {
  color: #ffecd1
}
.job__apply__inner {
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 40rem
}
.job__apply__summary {
  width: 100%
}
* + .job__apply__summary {
  margin-top: 2.0225rem
}
.job__apply__summary p {
  color: rgba(255,255,255,0.8)
}
.job__apply__summary p a,
.job__apply__summary p a:visited {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  color: #fff
}
.job__apply__summary p a:active,
.job__apply__summary p a:visited:active,
.job__apply__summary p a:focus,
.job__apply__summary p a:visited:focus,
.job__apply__summary p a:hover,
.job__apply__summary p a:visited:hover {
  color: #fff
}
.job__apply .form__fields {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 1.618rem
}
.job__apply * + .form_component {
  margin-top: 0
}
.job__apply .form_component {
  max-width: 100%
}
.job__form {
  border-radius: 8px;
  border: 1px solid #08245d;
  background-image: linear-gradient(to right top,hsla(220,100%,3%,0.9),hsla(220,85%,20%,0.9)),url("data:image/svg+xml, %3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.7' numOctaves='2' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  margin: 1.618rem auto;
  padding: 1.618rem
}
.job__form .form__fields {
  display: flex;
  flex-direction: column;
  gap: 1.618rem
}
.job__form .form_component__simulated_checkbox__label a,
.job__form .form_component__simulated_checkbox__label a:visited {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  color: #fff
}
.job__form .form_component__simulated_checkbox__label a:active,
.job__form .form_component__simulated_checkbox__label a:visited:active,
.job__form .form_component__simulated_checkbox__label a:focus,
.job__form .form_component__simulated_checkbox__label a:visited:focus,
.job__form .form_component__simulated_checkbox__label a:hover,
.job__form .form_component__simulated_checkbox__label a:visited:hover {
  color: #fff
}
.jobs__benefits__list {
  padding-bottom: 6.472rem;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1480px;
  display: grid;
  align-items: stretch;
  grid-template-columns: repeat(1,1fr);
  gap: 1.618rem
}
@media only screen and (min-width:1081px) {
  .jobs__benefits__list {
    grid-template-columns: repeat(2,1fr)
  }
}
.jobs__benefits__item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.618rem;
  width: 100%;
  max-width: 42.5rem;
  border-radius: 16px;
  border: 1px solid #e8ebf0;
  background-image: linear-gradient(to top right,#f1f2f4,#fff,#f1f2f4);
  padding: 1.618rem
}
@media only screen and (max-width:1080px) {
  .jobs__benefits__item {
    margin-right: auto;
    margin-left: auto
  }
}
@media only screen and (min-width:1081px) {
  .jobs__benefits__item {
    max-width: 100%
  }
}
.jobs__benefits__item__icon {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center
}
.jobs__benefits__item__icon svg {
  height: 5.177600000000001rem;
  width: 5.177600000000001rem;
  color: #214183
}
.jobs__benefits__item__text {
  flex: 1 1 auto
}
.jobs__benefits__item__heading {
  display: block;
  line-height: 1.45;
  font-family: "brown__bold",sans-serif;
  font-weight: normal;
  max-width: 60ch;
  letter-spacing: -.02em;
  font-size: 1.12rem;
  font-size: var(--fz-ratio-power--one);
  color: #1a2232
}
@media only screen and (min-width:581px) {
  .jobs__benefits__item__heading {
    font-size: 1.15rem;
    font-size: var(--fz-ratio-power--one)
  }
}
@media only screen and (min-width:881px) {
  .jobs__benefits__item__heading {
    font-size: 1.18rem;
    font-size: var(--fz-ratio-power--one)
  }
}
.reading_content * + .jobs__benefits__item__heading {
  margin-top: 1.624rem;
  margin-top: var(--sp-ratio-power--one)
}
@media only screen and (min-width:581px) {
  .reading_content * + .jobs__benefits__item__heading {
    margin-top: 1.6675rem;
    margin-top: var(--sp-ratio-power--one)
  }
}
@media only screen and (min-width:881px) {
  .reading_content * + .jobs__benefits__item__heading {
    margin-top: 1.711rem;
    margin-top: var(--sp-ratio-power--one)
  }
}
.jobs__benefits__item__summary {
  display: block;
  font-weight: normal;
  font-family: "brown__regular",sans-serif;
  letter-spacing: -.04em;
  font-size: 1rem;
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one)
}
* + .jobs__benefits__item__summary {
  margin-top: .5393333333333333rem
}
@media only screen and (min-width:581px) {
  .jobs__benefits__item__summary {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  .jobs__benefits__item__summary {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
.jobs__openings {
  border-bottom: 2px solid #0a1b3e;
  background-color: #040f25
}
.jobs__openings__inner {
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1480px;
  padding-top: 6.472rem;
  padding-bottom: 6.472rem
}
.jobs__openings__list {
  display: grid;
  grid-template-columns: repeat(1,1fr);
  gap: 1.618rem;
  width: 100%;
  max-width: 100%
}
@media only screen and (min-width:781px) {
  .jobs__openings__list {
    flex: 1 1 auto
  }
}
@media only screen and (min-width:681px) {
  .jobs__openings__list {
    grid-template-columns: repeat(2,1fr)
  }
}
.jobs__openings__item {
  border-radius: 8px;
  border: 1px solid #08245d;
  background-image: linear-gradient(to right top,hsla(220,100%,3%,0.9),hsla(220,85%,20%,0.9)),url("data:image/svg+xml, %3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.7' numOctaves='2' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  padding: 1.618rem
}
.jobs__openings__item * + .anchor_further {
  margin-top: .809rem
}
.jobs__openings__role {
  display: block;
  line-height: 1.45;
  font-family: "brown__bold",sans-serif;
  font-weight: normal;
  max-width: 60ch;
  letter-spacing: -.02em;
  font-size: 1.12rem;
  font-size: var(--fz-ratio-power--one)
}
@media only screen and (min-width:581px) {
  .jobs__openings__role {
    font-size: 1.15rem;
    font-size: var(--fz-ratio-power--one)
  }
}
@media only screen and (min-width:881px) {
  .jobs__openings__role {
    font-size: 1.18rem;
    font-size: var(--fz-ratio-power--one)
  }
}
.reading_content * + .jobs__openings__role {
  margin-top: 1.624rem;
  margin-top: var(--sp-ratio-power--one)
}
@media only screen and (min-width:581px) {
  .reading_content * + .jobs__openings__role {
    margin-top: 1.6675rem;
    margin-top: var(--sp-ratio-power--one)
  }
}
@media only screen and (min-width:881px) {
  .reading_content * + .jobs__openings__role {
    margin-top: 1.711rem;
    margin-top: var(--sp-ratio-power--one)
  }
}
.jobs__openings__role a {
  color: #ffecd1
}
* + .jobs__openings__definitions__list {
  margin-top: .809rem
}
.jobs__openings__definitions__item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: .2rem
}
.jobs__openings__definitions__icon {
  flex: 0 0 auto;
  padding-top: .2rem
}
.jobs__openings__definitions__icon svg {
  height: 1.077588rem;
  width: 1.077588rem;
  color: rgba(255,255,255,0.8)
}
.jobs__openings__definitions__text {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  gap: .5393333333333333rem
}
.jobs__openings__definitions__term,
.jobs__openings__definitions__description {
  display: block;
  color: rgba(255,255,255,0.8)
}
.jobs__openings__definitions__term::after {
  content: ":"
}
.insight_detail__main__inner {
  padding-top: 6.472rem;
  padding-bottom: 6.472rem;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1480px
}
.insight_detail__image {
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 75ch;
  overflow: hidden;
  border-radius: 8px
}
.insight_detail__date_created {
  display: block;
  text-align: center;
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one)
}
* + .insight_detail__date_created {
  margin-top: 2.156794rem
}
@media only screen and (min-width:581px) {
  .insight_detail__date_created {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  .insight_detail__date_created {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
.insight_detail__content {
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 75ch;
  width: 100%
}
* + .insight_detail__content {
  margin-top: 1.618rem
}
.platform__introduction__solutions__list {
  display: grid;
  grid-template-columns: repeat(1,1fr);
  gap: .809rem
}
* + .platform__introduction__solutions__list {
  margin-top: 3.236rem
}
@media only screen and (min-width:581px) {
  .platform__introduction__solutions__list {
    grid-template-columns: repeat(2,1fr);
    align-items: start
  }
}
@media only screen and (min-width:581px) {
  .platform__introduction__solutions__list {
    gap: 1.618rem
  }
}
.platform__introduction__solutions__item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: .5393333333333333rem;
  border-radius: 8px;
  border: 1px solid #040f25;
  background-image: linear-gradient(to top right,#0a1b3e,#214183,#0a1b3e);
  padding: 1.077588rem
}
@media only screen and (min-width:881px) {
  .platform__introduction__solutions__item {
    border-radius: 16px
  }
}
@media only screen and (min-width:481px) {
  .platform__introduction__solutions__item {
    padding: .809rem;
    padding-bottom: .5rem
  }
}
.platform__introduction__solutions__icon {
  flex: 0 0 auto;
  display: flex;
  transform: translateY(.1em)
}
.platform__introduction__solutions__icon svg {
  height: 1.077588rem;
  width: 1.077588rem
}
.platform__introduction__solutions__text {
  flex: 1 1 auto;
  line-height: 1.4;
  color: #fff
}
@media only screen and (max-width:480px) {
  .platform__introduction__solutions__text {
    font-size: 1.12rem;
    font-size: var(--fz-ratio-power--one)
  }
}
@media only screen and (max-width:480px) and (min-width:581px) {
  .platform__introduction__solutions__text {
    font-size: 1.15rem;
    font-size: var(--fz-ratio-power--one)
  }
}
@media only screen and (max-width:480px) and (min-width:881px) {
  .platform__introduction__solutions__text {
    font-size: 1.18rem;
    font-size: var(--fz-ratio-power--one)
  }
}
.platform__screenshot {
  width: 100%;
  max-width: 100%;
  background-color: #0a1b3e;
  padding-top: 6.472rem;
  padding-bottom: 6.472rem
}
.platform__screenshot__figure {
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1480px
}
.platform__screenshot__figure img {
  border-radius: 16px;
  border: 1px solid #214183
}
.platform__process_explanation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1480px;
  padding-bottom: 6.472rem
}
.platform__process_explanation__list {
  width: fit-content;
  max-width: 100%
}
* + .platform__process_explanation__list {
  margin-top: 3.236rem
}
.platform__process_explanation__item {
  will-change: opacity;
  transition-property: opacity;
  transition-duration: .8s;
  transition-timing-function: ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: .5393333333333333rem;
  width: fit-content;
  opacity: 0
}
* + .platform__process_explanation__item {
  margin-top: 1.618rem
}
.platform__process_explanation__list--is_visible .platform__process_explanation__item {
  opacity: 1
}
.platform__process_explanation__list--is_visible .platform__process_explanation__item:nth-child(2) {
  transition-delay: .8s
}
.platform__process_explanation__list--is_visible .platform__process_explanation__item:nth-child(3) {
  transition-delay: 1.6s
}
.platform__process_explanation__list--is_visible .platform__process_explanation__item:nth-child(4) {
  transition-delay: 2.4s
}
@media only screen and (min-width:881px) {
  .platform__process_explanation__item:nth-child(2) {
    padding-left: 1.618rem
  }
}
@media only screen and (min-width:881px) {
  .platform__process_explanation__item:nth-child(3) {
    padding-left: 9.6rem
  }
}
@media only screen and (min-width:881px) {
  .platform__process_explanation__item:nth-child(4) {
    padding-left: 17.6rem
  }
}
@media only screen and (min-width:881px) {
  .platform__process_explanation__item:not(:first-child) .platform__process_explanation__step_number_and_text {
    transform: translateY(2.7rem)
  }
}
.platform__process_explanation__step_arrow {
  flex: 0 0 auto;
  width: 6rem
}
@media only screen and (max-width:880px) {
  .platform__process_explanation__step_arrow {
    display: none
  }
}
.platform__process_explanation__step_number_and_text {
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .809rem
}
.platform__process_explanation__step_number {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.236rem;
  width: 3.236rem;
  box-shadow: 0 2px 6px #f1f2f4;
  border-radius: 50%;
  border: 1px solid #e8ebf0;
  background-image: linear-gradient(to top right,#f1f2f4,#fff,#f1f2f4);
  padding: .5393333333333333rem;
  color: #214183
}
.platform__process_explanation__step_number__text {
  transform: translateY(.1em);
  line-height: 1.1;
  font-size: 1.2544rem;
  font-size: var(--fz-ratio-power--two)
}
@media only screen and (min-width:581px) {
  .platform__process_explanation__step_number__text {
    font-size: 1.3225rem;
    font-size: var(--fz-ratio-power--two)
  }
}
@media only screen and (min-width:881px) {
  .platform__process_explanation__step_number__text {
    font-size: 1.3924rem;
    font-size: var(--fz-ratio-power--two)
  }
}
.platform__process_explanation__step_text {
  flex: 0 1 auto
}
.platform__process_explanation__step_period {
  display: block;
  text-transform: uppercase;
  line-height: 1.1;
  letter-spacing: .02em;
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: #7b879d
}
@media only screen and (min-width:581px) {
  .platform__process_explanation__step_period {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  .platform__process_explanation__step_period {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
.platform__process_explanation__step_explanation {
  display: block;
  line-height: 1.45;
  font-size: 1.2544rem;
  font-size: var(--fz-ratio-power--two)
}
* + .platform__process_explanation__step_explanation {
  margin-top: .26966666666666667rem
}
@media only screen and (min-width:581px) {
  .platform__process_explanation__step_explanation {
    font-size: 1.3225rem;
    font-size: var(--fz-ratio-power--two)
  }
}
@media only screen and (min-width:881px) {
  .platform__process_explanation__step_explanation {
    font-size: 1.3924rem;
    font-size: var(--fz-ratio-power--two)
  }
}
.policy__inner {
  position: relative;
  z-index: auto;
  padding-top: 6.472rem;
  padding-bottom: 6.472rem;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 75ch
}
.policy__inner::before {
  content: "";
  position: absolute;
  z-index: auto;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background-image: linear-gradient(to right,hsla(34.1,100%,66%,0) 0,#ffdaa9 50%,hsla(34.1,100%,66%,0) 100%)
}
.policy__inner h1,
.policy__inner h2,
.policy__inner h3,
.policy__inner h4 {
  color: #1a2232
}
.product__benefits__list {
  display: grid;
  grid-template-columns: repeat(1,1fr);
  gap: .809rem
}
* + .product__benefits__list {
  margin-top: 2.0225rem
}
@media only screen and (min-width:481px) {
  .product__benefits__list {
    grid-template-columns: repeat(2,1fr);
    align-items: start
  }
}
@media only screen and (min-width:581px) {
  .product__benefits__list {
    gap: 1.618rem
  }
}
.product__benefits__item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: .5393333333333333rem;
  border-radius: 16px;
  border: 1px solid #040f25;
  background-image: linear-gradient(to top right,#0a1b3e,#214183,#0a1b3e);
  padding: 1.077588rem
}
@media only screen and (min-width:481px) {
  .product__benefits__item {
    padding: .809rem;
    padding-bottom: .5rem
  }
}
.product__benefits__item__icon {
  flex: 0 0 auto;
  display: flex;
  transform: translateY(.1em)
}
.product__benefits__item__icon svg {
  height: 1.077588rem;
  width: 1.077588rem
}
.product__benefits__item__text {
  flex: 1 1 auto;
  line-height: 1.4;
  color: #fff
}
@media only screen and (max-width:480px) {
  .product__benefits__item__text {
    font-size: 1.12rem;
    font-size: var(--fz-ratio-power--one)
  }
}
@media only screen and (max-width:480px) and (min-width:581px) {
  .product__benefits__item__text {
    font-size: 1.15rem;
    font-size: var(--fz-ratio-power--one)
  }
}
@media only screen and (max-width:480px) and (min-width:881px) {
  .product__benefits__item__text {
    font-size: 1.18rem;
    font-size: var(--fz-ratio-power--one)
  }
}
* + .product__features__list {
  margin-top: 3.236rem
}
@media only screen and (max-width:980px) {
  .product__features__list {
    width: fit-content
  }
}
.product__features__item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.077588rem;
  padding-top: .5393333333333333rem;
  padding-bottom: .5393333333333333rem
}
.product__features__item:first-child {
  padding-top: 0
}
.product__features__item:not(:last-child) {
  border-bottom: 1px solid #0a1b3e
}
.product__features__item__icon {
  flex: 0 0 auto;
  display: flex
}
.product__features__item__icon svg {
  transform: translateY(.15em);
  height: 1.077588rem;
  width: 1.077588rem;
  color: #e8ebf0
}
.product__features__item__text {
  flex: 1 1 auto;
  display: block;
  line-height: 1.5;
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: rgba(255,255,255,0.8)
}
@media only screen and (min-width:581px) {
  .product__features__item__text {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  .product__features__item__text {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
.product__features__description .product__features__item__text {
  transform: translateY(.2em)
}
.product__features__term {
  flex: 0 0 9rem;
  display: flex;
  gap: .5393333333333333rem;
  align-items: flex-start;
  font-weight: normal;
  font-family: "brown__bold",sans-serif;
  letter-spacing: -.02em
}
.product__features__description {
  flex: 1 1 auto
}
.product__instructions__list {
  list-style-type: decimal;
  list-style-position: outside;
  display: grid;
  grid-template-columns: repeat(1,1fr);
  gap: 1.618rem;
  padding-left: 1rem
}
* + .product__instructions__list {
  margin-top: 1.618rem
}
.product__instructions__item {
  max-width: 75ch
}
.product__instructions__item::marker {
  color: #8f9ebc
}
.product__instructions__item a,
.product__instructions__item a:visited {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  color: #0f45b2
}
.product__instructions__item a:active,
.product__instructions__item a:visited:active,
.product__instructions__item a:focus,
.product__instructions__item a:visited:focus,
.product__instructions__item a:hover,
.product__instructions__item a:visited:hover {
  color: #0036a1
}
.standard_section--dark_background {
  background-color: #040f25
}
.standard_section--dark_background:last-child {
  border-bottom: 2px solid #0a1b3e
}
.standard_section--dark_background .section_heading,
.standard_section--dark_background .section_summary {
  color: #ffecd1
}
.standard_section--dark_background .section_heading::after {
  background-color: #0a1b3e
}
.standard_section--dark_background p {
  color: rgba(255,255,255,0.8)
}
.standard_section--dark_background .anchor_further {
  border-bottom-color: rgba(255,255,255,0.8)
}
.standard_section--dark_background .anchor_further:active,
.standard_section--dark_background .anchor_further:focus,
.standard_section--dark_background .anchor_further:hover {
  border-bottom-color: #fff
}
.standard_section--dark_background .anchor_further:active .anchor_further__icon,
.standard_section--dark_background .anchor_further:focus .anchor_further__icon,
.standard_section--dark_background .anchor_further:hover .anchor_further__icon,
.standard_section--dark_background .anchor_further:active .anchor_further__text,
.standard_section--dark_background .anchor_further:focus .anchor_further__text,
.standard_section--dark_background .anchor_further:hover .anchor_further__text {
  color: #fff
}
.standard_section--dark_background .anchor_further__icon {
  color: rgba(255,255,255,0.8)
}
.standard_section--dark_background .anchor_further__text {
  color: #fff
}
.standard_section__inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1480px;
  padding-top: 6.472rem;
  padding-bottom: 6.472rem
}
@media only screen and (min-width:881px) {
  .standard_section__inner {
    flex-direction: row
  }
}
@media only screen and (max-width:880px) {
  .standard_section__inner {
    gap: 1.618rem
  }
}
.standard_section__section_heading {
  display: flex
}
@media only screen and (min-width:881px) {
  .standard_section__section_heading {
    flex: 0 1 20%
  }
}
@media only screen and (min-width:881px) {
  .standard_section__section_heading {
    padding-top: 1.3rem;
    padding-right: 1.618rem
  }
}
@media only screen and (min-width:881px) {
  .standard_section__content {
    flex: 0 1 60%
  }
}
@media only screen and (max-width:880px) {
  .standard_section__content {
    width: 100%;
    max-width: 100%
  }
}
* + .standard_section__text {
  margin-top: 1.9416rem
}
.standard_section__text:first-child {
  padding-top: 1rem
}
* + .summary_and_content {
  margin-top: 3.236rem
}
.summary_and_content--priority .summary_and_content__summary {
  padding: 1.2135rem 1.618rem
}
.summary_and_content--priority .summary_and_content__summary__text {
  display: block;
  line-height: 1.45;
  font-family: "brown__bold",sans-serif;
  font-weight: normal;
  max-width: 52.5ch;
  letter-spacing: -.026em;
  font-size: 1.2544rem;
  font-size: var(--fz-ratio-power--two)
}
@media only screen and (min-width:581px) {
  .summary_and_content--priority .summary_and_content__summary__text {
    font-size: 1.3225rem;
    font-size: var(--fz-ratio-power--two)
  }
}
@media only screen and (min-width:881px) {
  .summary_and_content--priority .summary_and_content__summary__text {
    font-size: 1.3924rem;
    font-size: var(--fz-ratio-power--two)
  }
}
.reading_content * + .summary_and_content--priority .summary_and_content__summary__text {
  margin-top: 1.81888rem;
  margin-top: var(--sp-ratio-power--two)
}
@media only screen and (min-width:581px) {
  .reading_content * + .summary_and_content--priority .summary_and_content__summary__text {
    margin-top: 1.917625rem;
    margin-top: var(--sp-ratio-power--two)
  }
}
@media only screen and (min-width:881px) {
  .reading_content * + .summary_and_content--priority .summary_and_content__summary__text {
    margin-top: 2.01898rem;
    margin-top: var(--sp-ratio-power--two)
  }
}
.summary_and_content--priority .summary_and_content__summary__icon {
  height: 2.0225rem;
  min-height: 2.0225rem;
  max-height: 2.0225rem;
  width: 2.0225rem;
  min-width: 2.0225rem;
  max-width: 2.0225rem
}
.summary_and_content--priority .summary_and_content__summary__icon svg {
  height: .809rem;
  width: .809rem
}
.summary_and_content--priority .summary_and_content__content {
  padding: 1.618rem
}
.summary_and_content__details {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  border-radius: 16px;
  border: 1px solid #e8ebf0;
  background-color: #fff
}
.summary_and_content__summary {
  will-change: border-color;
  transition-property: border-color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  cursor: pointer;
  position: relative;
  z-index: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: .809rem;
  background-image: linear-gradient(to top right,#f1f2f4,#fff,#f1f2f4);
  padding: 1.077588rem
}
.summary_and_content__summary:focus .summary_and_content__summary__icon,
.summary_and_content__summary:hover .summary_and_content__summary__icon {
  border-color: #0036a1;
  color: #0036a1
}
.summary_and_content__summary::-webkit-details-marker {
  display: none
}
.summary_and_content__summary__text {
  flex: 1 1 auto
}
.summary_and_content__summary__text::selection {
  background-color: transparent;
  text-shadow: unset;
  color: 
}
.summary_and_content__summary__icon {
  flex: 0 0 auto;
  will-change: border-color,color,transform;
  transition-property: border-color,color,transform;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  transform: rotate(45deg);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .1618rem;
  border-radius: 50%;
  border: 1px solid #0f45b2;
  height: 1.618rem;
  min-height: 1.618rem;
  max-height: 1.618rem;
  width: 1.618rem;
  min-width: 1.618rem;
  max-width: 1.618rem;
  color: #0f45b2
}
.summary_and_content__summary__icon svg {
  height: .5393333333333333rem;
  width: .5393333333333333rem
}
details[open] .summary_and_content__summary__icon {
  transform: rotate(90deg)
}
.summary_and_content__content {
  border-radius: 0 0 8px 8px;
  padding: 1.077588rem
}
details[open] .summary_and_content__content {
  border-top: 1px solid #e8ebf0
}
.summary_and_content__content h2,
.summary_and_content__content h3,
.summary_and_content__content h4 {
  color: #ffecd1
}
.summary_and_content__content li::marker {
  color: #e8ebf0
}
.table_wrap {
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  border: 1px solid #fffbf1
}
* + .table_wrap {
  margin-top: 1.618rem
}
.table_wrap .table_wrap {
  border: none
}
table {
  display: table;
  table-layout: auto;
  border-collapse: separate;
  border-radius: 8px;
  border-spacing: 0;
  width: fit-content;
  width: 100%;
  max-width: 100%;
  font-size: .7971938775510203rem;
  font-size: var(--fz-ratio-power--minus-two)
}
@media only screen and (min-width:581px) {
  table {
    font-size: .7561436672967865rem;
    font-size: var(--fz-ratio-power--minus-two)
  }
}
@media only screen and (min-width:881px) {
  table {
    font-size: .7181844297615629rem;
    font-size: var(--fz-ratio-power--minus-two)
  }
}
table th,
table td {
  min-width: 180px;
  border-bottom: 1px solid #e8ebf0
}
@media only screen and (max-width:480px) {
  table th,
  table td {
    border-right: 1px solid #e8ebf0
  }
}
table th:last-child,
table td:last-child {
  border-right: 0
}
table th {
  vertical-align: bottom;
  background-color: #000510;
  padding: .5393333333333333rem .809rem;
  text-align: left;
  font-weight: normal;
  font-family: "brown__bold",sans-serif;
  letter-spacing: -.02em;
  color: #fff
}
@media only screen and (min-width:481px) {
  table th:not(:last-child) {
    border-right: 1px solid #e8ebf0
  }
}
table td {
  padding: .5393333333333333rem .809rem
}
@media only screen and (min-width:481px) {
  table td:not(:last-child) {
    border-right: 1px solid #e8ebf0
  }
}
table tbody {
  min-width: 100%;
  color: #fff
}
table tbody p {
  color: #fff
}
@media only screen and (max-width:480px) {
  table tbody tr:last-child {
    border-radius: 0 0 8px 8px
  }
}
table tbody tr:last-child td {
  border-bottom: 0
}
table tbody tr:nth-child(odd) {
  background-color: #040f25
}
table tbody tr:nth-child(even) {
  background-color: #0a1b3e
}
table tbody th {
  vertical-align: top;
  font-family: normal
}
table a,
table a:visited,
table a:visited:visited {
  will-change: color;
  transition-property: color;
  transition-duration: .14s;
  transition-timing-function: ease-out;
  color: #0f45b2
}
table a:active,
table a:visited:active,
table a:visited:visited:active,
table a:focus,
table a:visited:focus,
table a:visited:visited:focus,
table a:hover,
table a:visited:hover,
table a:visited:visited:hover {
  color: #0036a1
}
.testimonial {
  border-radius: 16px;
  border: 1px solid #e8ebf0;
  background-image: linear-gradient(to top right,#f1f2f4,#fff,#f1f2f4);
  padding: 1.618rem
}
* + .testimonial {
  margin-top: 3.236rem
}
.testimonial__quotation_mark_and_text {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: .809rem
}
.testimonial__quotation_mark {
  flex: 0 0 auto
}
.testimonial__quotation_mark svg {
  width: 3.2rem;
  color: #f1f2f4
}
.testimonial__text {
  flex: 1 1 auto
}
.testimonial__text p {
  font-size: 1.12rem;
  font-size: var(--fz-ratio-power--one);
  line-height: 1.45
}
@media only screen and (min-width:581px) {
  .testimonial__text p {
    font-size: 1.15rem;
    font-size: var(--fz-ratio-power--one)
  }
}
@media only screen and (min-width:881px) {
  .testimonial__text p {
    font-size: 1.18rem;
    font-size: var(--fz-ratio-power--one)
  }
}
.testimonial__image_and_citation {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .809rem
}
* + .testimonial__image_and_citation {
  margin-top: 1.618rem
}
.testimonial__image {
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 50%;
  object-fit: cover;
  filter: grayscale(100%) sepia(1) hue-rotate(178deg)
}
.testimonial__name,
.testimonial__role {
  display: block;
  font-size: .8928571428571428rem;
  font-size: var(--fz-ratio-power--minus-one)
}
@media only screen and (min-width:581px) {
  .testimonial__name,
  .testimonial__role {
    font-size: .8695652173913044rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
@media only screen and (min-width:881px) {
  .testimonial__name,
  .testimonial__role {
    font-size: .8474576271186441rem;
    font-size: var(--fz-ratio-power--minus-one)
  }
}
.testimonial__name {
  font-weight: normal;
  font-family: "brown__bold",sans-serif;
  letter-spacing: -.02em
}
.testimonial__name__role {
  color: #7b879d
}
* + .testimonial__name__role {
  margin-top: .4045rem
}